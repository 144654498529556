import { InputWithoutLabelElement } from 'components/common/Input/index.js';
import { ReactComponent as IconDocumentUpload } from 'static/Common/icon-document-upload.svg';
import './main.scss';

const TextAndFileInput = ({ className = '', onFileChoose, fileError, ...rest }) => (
    <InputWithoutLabelElement {...rest} className={`${className} text-and-file-input`} error={rest.error || fileError}>
        <label className={`file-input-label ${fileError ? 'invalid' : ''}`}>
            <input type='file' onChange={onFileChoose} />
            <IconDocumentUpload />
        </label>
    </InputWithoutLabelElement>
);

export default TextAndFileInput;
