import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const ADD_MILEAGE = 'ADD_MILEAGE';
export const UPDATE_MILEAGE = 'UPDATE_MILEAGE';
export const DELETE_MILEAGE_REQUEST = 'DELETE_MILEAGE_REQUEST';
export const DELETE_MILEAGE = 'DELETE_MILEAGE';
export const GET_MILEAGE_LIST = 'GET_MILEAGE_LIST';
export const GET_MILEAGE_DETAILS = 'GET_MILEAGE_DETAILS';
export const GET_MILEAGE_FIELDS = 'GET_MILEAGE_FIELDS';
export const MILEAGE_UPDATE_REQUEST = 'MILEAGE_UPDATE_REQUEST';
export const MILEAGE_ADD_REQUEST = 'MILEAGE_ADD_REQUEST';

export function addMileage(body) {
    return {
        type: MILEAGE_ADD_REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.mileage.create, method: 'POST', body },
                commit: { type: ADD_MILEAGE, meta: { showSuccess: true } },
                rollback: { type: FAILURE },
            },
        },
    };
}

export function updateMileageDetails(body, id) {
    return {
        type: MILEAGE_UPDATE_REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.mileage.detail.replace(':id', id), method: 'PUT', body },
                commit: { type: UPDATE_MILEAGE, meta: { id, showSuccess: true } },
                rollback: { type: FAILURE },
            },
        },
    };
}

export function deleteMileage(id) {
    return {
        type: DELETE_MILEAGE_REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.mileage.detail.replace(':id', id), method: 'DELETE' },
                commit: { type: DELETE_MILEAGE, meta: { showSuccess: true } },
                rollback: { type: FAILURE },
            },
            id,
        },
    };
}

export function getMileageList(id) {
    return {
        [CALL_API]: {
            endpoint: API.mileage.list.replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_MILEAGE_LIST, FAILURE],
        },
    };
}

export function getMileageDetails(id) {
    return {
        [CALL_API]: {
            endpoint: API.mileage.detail.replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_MILEAGE_DETAILS, FAILURE],
        },
    };
}

export function getMileageFields() {
    return {
        [CALL_API]: {
            endpoint: API.mileage.fields,
            method: 'GET',
            types: [REQUEST, GET_MILEAGE_FIELDS, FAILURE],
        },
    };
}
