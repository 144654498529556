import { GET_CAR_BOOKINGS, GET_CAR_HOME_DATA } from './actions';

const initialState = {
    mileage: {},
    refuel: {},
    bookings: [],
};

export default function home(state = initialState, action) {
    switch(action.type) {
        case GET_CAR_HOME_DATA: {
            const { latest_mileage, latest_refuel, emission_average, consumption_average, book_next } = action.payload;

            return {
                ...state,
                mileage: latest_mileage,
                refuel: latest_refuel,
                booking: book_next,
                emission_average,
                consumption_average,
            };
        }
        case GET_CAR_BOOKINGS: {
            return {
                ...state,
                bookings: action.response,
            };
        }

        default: { return state; }
    }
}
