import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { t } from 'ttag';
import moment from 'moment';
import TitleContainer from 'components/common/TitleContainer/index.js';
import useRefMounted from 'hooks/useRefMounted.js';
import { fieldToInput, validate, FIELD_SORT_ORDER } from 'components/Mileage/utils.js';
import { addMileage, getMileageDetails, deleteMileage, updateMileageDetails, getMileageFields } from '../actions.js';
import PopupModal from '../../common/PopupModal';
import './main.scss';

const FIELDS_NOT_TO_BE_SHOWN_WITH_WIALON = [
    'address_start',
    'address_stop',
    'period_km',
    'total_km_start',
    'total_km_stop',
];

const AddOrEditMileage = ({
    addMileage, getMileageDetails, mileageDetails, updateMileageDetails, deleteMileage, getMileageFields, fields,
    currentVehicle,
    companyID, organizationID,
    match, history,
}) => {
    const { journey_status, wialon_status } = mileageDetails;
    const { id: car_id, shared_to, wialon } = currentVehicle;
    const { id } = match.params;

    const refMounted = useRefMounted();
    const [disabled, setDisabled] = useState(!!id);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [popupVisible, setPopupVisible] = useState(false);
    const disabledSubmit = useSelector(state => state.Mileage.disabled);

    useEffect(() => { getMileageFields(); }, []);

    useEffect(() => {
        const errorElements = document.getElementsByClassName('has-error');
        errorElements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [errors]);

    useEffect(() => {
        const updateValues = currentValues => {
            const entries = Object.entries(fields).map(([field]) => [field, currentValues[field]]);

            return ({
                ...Object.fromEntries(entries),
                ...(organizationID && { organization_id: organizationID }),
            });
        };

        setValues(updateValues);
    }, [fields]);

    useEffect(() => {
        if(id) {
            getMileageDetails(id).then(res => {
                if(res.error) {
                    console.warn('Couldn\'t get mileage record for {id}', id, '\nResponse:', res);
                    history.push('/mileage');
                } else if(refMounted.current) {
                    const { since, until, journey_status, wialon_status } = res;

                    setValues(values => ({
                        ...values,
                        ...res,
                        since: since ? moment(since).format('YYYY-MM-DDTHH:mm') : null,
                        until: until ? moment(until).format('YYYY-MM-DDTHH:mm') : null,
                        ...(organizationID && { organization_id: organizationID }),
                    }));

                    setDisabled((journey_status === 'ongoing') || (wialon_status === 'waiting'));
                }
            });
        }
    }, [id]);

    const onSubmit = e => {
        e.preventDefault();

        if(!validate(values, setErrors)) return;

        const missingToNull = Object.keys(values).reduce((acc, k) => {
            acc[k] = values[k] || null;
            return acc;
        }, {});

        const { since, until, organization_id } = values;

        const payload = {
            car_id,
            ...missingToNull,
            since: since ? moment(since).utc() : null,
            until: until ? moment(until).utc() : null,
            company_id: (!organization_id && !!companyID) ? companyID : null,
            wialon: id ? false : wialon,
        };

        if(id) updateMileageDetails(payload, id);
        else addMileage(payload);

        history.push('/mileage');
    };

    const onDelete = e => {
        e.preventDefault();

        if(navigator.onLine) deleteMileage(id).then(() => history.replace('/mileage'));
        else {
            deleteMileage(id);
            history.replace('/mileage');
        }
    };

    const onChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    if(!id && wialon) FIELDS_NOT_TO_BE_SHOWN_WITH_WIALON.forEach(field => delete fields[field]);

    return (
        <div id='add-mileage'>
            <TitleContainer path='/mileage' title={id ? t`EDIT_MILEAGE_TITLE` : t`ADD_MILEAGE_TITLE`} />
            {((journey_status === 'ongoing') || (wialon_status === 'waiting')) && (
                <span className='editability-note'>
                    {journey_status === 'ongoing' ? t`CANT_EDIT_ONGOING_JOURNEY` : t`CANT_EDIT_PENDING_WIALON_DATA`}
                </span>
            )}
            <form className='add-mileage-form' onSubmit={onSubmit}>
                <fieldset className='fieldset' disabled={disabled}>
                    {Object.keys(fields)
                        .sort((a, b) => FIELD_SORT_ORDER.indexOf(a) - FIELD_SORT_ORDER.indexOf(b))
                        .map(field => fieldToInput(
                            field,
                            values[field],
                            errors[field],
                            onChange,
                            shared_to,
                            companyID,
                        ))}
                </fieldset>
                <div className='button-container'>
                    <input
                        type='submit'
                        className='button--primary'
                        value={t`SAVE_BUTTON`}
                        disabled={disabled || disabledSubmit}
                    />
                    {!!id && (
                        <button
                            type='button'
                            className='button--secondary'
                            onClick={() => setPopupVisible(true)}
                            disabled={disabled}
                        >
                            {t`DELETE_BUTTON`}
                        </button>
                    )}
                </div>
            </form>
            <PopupModal
                visible={popupVisible}
                title={t`CONFIRM_HEADER`}
                body={t`CONFIRM_MILEAGE_DELETE`}
                onCancel={() => setPopupVisible(false)}
                onConfirm={onDelete}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    ...state.Mileage,
    currentVehicle: state.SelectVehicle.currentVehicle,
    organizationID: state.Auth.organizationID,
    companyID: state.Auth.companyID,
});

const mapDispatchToProps = ({ addMileage, getMileageDetails, updateMileageDetails, deleteMileage, getMileageFields });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddOrEditMileage));
