import {
    REQUEST,
    FAILURE,
    GET_BOOKING_LIST,
    FIND_SUITABLE_CARS,
    GET_SINGLE_BOOKING,
    DELETE_BOOKING,
} from './actions.js';

const initialState = {
    bookings: [],
    cars: [],
};

function booking(state = initialState, action) {
    switch(action.type) {
        case REQUEST: { return state; }
        case FAILURE: {
            return { ...state, error: action.error };
        }
        case DELETE_BOOKING: {
            const id = parseInt(action.meta.id, 10);
            const newBookings = state.bookings.filter(book => book.id !== id);
            return { ...state, bookings: newBookings };
        }
        case GET_SINGLE_BOOKING: {
            const newBookings = state.bookings.filter(book => book.id !== action.response.id);
            return { ...state, bookings: [...newBookings, action.response] };
        }
        case GET_BOOKING_LIST: {
            return { ...state, bookings: action.response };
        }
        case FIND_SUITABLE_CARS: {
            return { ...state, cars: action.response };
        }
        default: { return state; }
    }
}

export default booking;
