import { Link } from 'react-router-dom';
import './main.scss';

const CardWithImage = ({
    className,
    label,
    secondary,
    src,
    alt,
    type,
    href, // <a />
    to, // <Link />
    onClick, // <button />
}) => {
    const finalClassName = `card-with-image ${className || ''}`;

    const Wrapper = ({ children }) => {
        let el = <div className={finalClassName}>{children}</div>;

        if(href) {
            el = <a href={href} target='_blank' rel='noopener noreferrer' onClick={onClick}>{children}</a>;
        } else if(to) {
            el = <Link className={finalClassName} to={to} onClick={onClick}>{children}</Link>;
        } else if(onClick) {
            // eslint-disable-next-line react/button-has-type
            el = <button type={type || 'button'} className={finalClassName} onClick={onClick}>{children}</button>;
        }

        return el;
    };

    return (
        <Wrapper>
            <div className='card-content'>
                <span className='card-label'>
                    {label}
                </span>
                <span className='card-secondary'>
                    {secondary}
                </span>
            </div>
            <div className='card-image-wrap'>
                <img className='card-image' src={src} alt={alt} />
            </div>
        </Wrapper>
    );
};

export default CardWithImage;
