import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LoadingBar, { showLoading, hideLoading } from 'react-redux-loading-bar';

import '../CoreLayout/index.scss';

/**
 * This loading component used as a root wrapper for rendering empty page when we are loading a route.
 */

const RouteLoading = ({ showLoading, hideLoading }) => {
    useEffect(() => {
        showLoading();
        return () => {
            hideLoading();
        };
    }, []);

    return (
        <div className='core-wrapper'>
            <main className='core-content'>
                <LoadingBar className='loading-bar' />
            </main>
        </div>
    );
};

const mapDispatchToProps = ({
    showLoading,
    hideLoading,
});

export default withRouter(connect(null, mapDispatchToProps)(RouteLoading));
