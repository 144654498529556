import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_FUEL_HISTORY = 'GET_FUEL_HISTORY';
export const ADD_FUEL_RECORD = 'ADD_FUEL_RECORD';
export const EDIT_FUEL_RECORD = 'EDIT_FUEL_RECORD';
export const GET_FUEL_RECORD = 'GET_FUEL_RECORD';
export const GET_FUEL_CATEGORIES = 'GET_FUEL_CATEGORIES';
export const DELETE_FUEL_RECORD_REQUEST = 'DELETE_FUEL_RECORD_REQUEST';
export const DELETE_FUEL_RECORD = 'DELETE_FUEL_RECORD';
export const ADD_FUEL_RECORD_REQUEST = 'ADD_FUEL_RECORD_REQUEST';
export const ADD_FUEL_RECORD_FAILURE = 'ADD_FUEL_RECORD_FAILURE';
export const EDIT_FUEL_RECORD_REQUEST = 'EDIT_FUEL_RECORD_REQUEST';
export const EDIT_FUEL_RECORD_FAILURE = 'EDIT_FUEL_RECORD_FAILURE';

export function getFuelHistory(carID) {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.refuel.list.replace(':id', carID),
            types: [REQUEST, GET_FUEL_HISTORY, FAILURE],
        },
    };
}

export function getSingleFuelRecord(id) {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.refuel.detail.replace(':id', id),
            types: [REQUEST, GET_FUEL_RECORD, FAILURE],
            id: parseInt(id, 10),
        },
    };
}

export function addFuelRecord(body) {
    const PENDING = Symbol('PENDING');

    return {
        type: ADD_FUEL_RECORD_REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.refuel.base, method: 'POST', body },
                commit: { type: ADD_FUEL_RECORD, meta: { showSuccess: true, PENDING } },
                rollback: { type: ADD_FUEL_RECORD_FAILURE, meta: { PENDING } },
            },
            body,
            PENDING,
        },
    };
}

export function editFuelRecord(id, body, rollbackData) {
    const PENDING = Symbol('PENDING');

    return {
        type: EDIT_FUEL_RECORD_REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.refuel.detail.replace(':id', id), method: 'PUT', body },
                commit: { type: EDIT_FUEL_RECORD, meta: { id, showSuccess: true, PENDING } },
                rollback: { type: EDIT_FUEL_RECORD_FAILURE, meta: { PENDING, rollbackData } },
            },
            body,
            PENDING,
        },

    };
}

export function deleteFuelRecord(id) {
    return {
        type: DELETE_FUEL_RECORD_REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.refuel.detail.replace(':id', id), method: 'DELETE' },
                commit: { type: DELETE_FUEL_RECORD, meta: { id, showSuccess: true } },
                rollback: { type: FAILURE },
            },
            id,
        },
    };
}

export function getFuelCategories() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.refuel.fuel_categories,
            types: [REQUEST, GET_FUEL_CATEGORIES, FAILURE],
        },
    };
}
