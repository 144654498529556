import { t } from 'ttag';
import { useHistory } from 'react-router-dom';
import useField from '../../../hooks/useField';
import { Input } from '../../common/Input';
import './main.scss';
import TitleContainer from '../../common/TitleContainer';

const AddBooking = () => {
    const history = useHistory();
    const startTime = useField(t`START_TIME`, 'date', 'datetime-local');
    const endTime = useField(t`END_TIME`, 'date', 'datetime-local');

    const onSubmit = e => {
        e.preventDefault();
        const hookFieldsValid = [startTime, endTime].map(f => f.validate()).every(v => v);

        if(!hookFieldsValid) return;

        history.push(`/booking/new/${startTime.value}/${endTime.value}`);
    };

    return (
        <section id='booking-form'>
            <TitleContainer path='/booking' title={t`NEW_BOOKING`} />
            <form onSubmit={onSubmit}>
                <Input {...startTime.inputProps} />
                <Input {...endTime.inputProps} />
                <button className='button button--primary' type='submit'>{t`FIND_AVAILABLE_CARS`}</button>

            </form>
        </section>
    );
};

export default AddBooking;
