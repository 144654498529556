import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_JOURNEY_CUSTOMERS = 'GET_JOURNEY_CUSTOMERS';
export const START_JOURNEY = 'START_JOURNEY';
export const END_JOURNEY = 'END_JOURNEY';
export const GET_ADDRESS = 'GET_ADDRESS';
export const INITIATE_JOURNEY_REQUEST = 'INITIATE_JOURNEY_REQUEST';
export const INITIATE_JOURNEY_FAILURE = 'INITIATE_JOURNEY_FAILURE';

export function getJourneyCustomers(id) {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.journey.customers.replace(':id', id),
            types: [REQUEST, GET_JOURNEY_CUSTOMERS, FAILURE],
        },
    };
}

export function startJourney(body, key) {
    return {
        type: INITIATE_JOURNEY_REQUEST,
        key,
        meta: {
            offline: {
                effect: { method: 'POST', endpoint: API.journey.base, body },
                commit: { type: START_JOURNEY, key },
                rollback: { type: INITIATE_JOURNEY_FAILURE, key },
            },
        },
    };
}

export function endJourney(body) {
    return {
        type: REQUEST,
        meta: {
            offline: {
                effect: { method: 'PUT', endpoint: API.journey.detail.replace(':id', body.id), body },
                commit: { type: END_JOURNEY },
                rollback: { type: FAILURE },
            },
        },
    };
}

export function getAddressFromCoords({ latitude: lat, longitude: long }, language) {
    return {
        [CALL_API]: {
            endpoint: API.journey.location,
            method: 'POST',
            types: [REQUEST, GET_ADDRESS, FAILURE],
            body: { lat, long, language },
        },
    };
}
