import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { t } from 'ttag';
import moment from 'moment';
import useField, { validateDecimal } from 'hooks/useField.js';
import useRefMounted from 'hooks/useRefMounted.js';
import TitleContainer from 'components/common/TitleContainer/index.js';
import Dropdown from 'components/common/Dropdown/index.js';
import { Input } from 'components/common/Input/index.js';
import { backendFileToDataURI, FUEL_TYPES, toBase64 } from 'utils.js';
import {
    addFuelRecord,
    editFuelRecord,
    getSingleFuelRecord,
    getFuelCategories,
    deleteFuelRecord,
} from 'components/Fuel/actions.js';
import { validate, FORMDATA_SHAPE } from 'components/Fuel/utils.js';
import './main.scss';
import PopupModal from '../../common/PopupModal';

const dataURIToBackendFile = str => ({
    content_base64: str.slice(str.indexOf(',') + 1),
    content_type: str.slice(str.indexOf(':') + 1, str.indexOf(';')),
});

const AddOrEditFuel = ({
    history, match,
    currentVehicle,
    addFuelRecord, editFuelRecord, getSingleFuelRecord, deleteFuelRecord,
    getFuelCategories, fuelCategories,
}) => {
    const { id } = match.params;
    const { state } = history.location; // If user navigates from card, fuel record info is in here
    const { id: car_id, car_data } = currentVehicle;
    const { fuel_type } = car_data;

    const BLANK = FORMDATA_SHAPE(car_id, fuel_type);

    const [disableFieldset, setDisableFieldset] = useState(false);
    const [errors, setErrors] = useState(FORMDATA_SHAPE());
    const [formData, setFormData] = useState(id ? state || BLANK : BLANK);
    const [rollbackData, setRollbackData] = useState({});
    const [popupVisible, setPopupVisible] = useState(false);
    const [preview, setPreview] = useState(null);
    const date = useField(t`DATE`, 'date', 'datetime-local');
    const volume = useField(t`VOLUME_(LITRES)`, 'volume', 'number');
    const price = useField(t`PRICE_(EUROS)`, 'price', 'number');
    const totalKm = useField(t`TOTAL_KM`, 'total_km', 'number');
    const refMounted = useRefMounted();
    const i18n = FUEL_TYPES();
    const disabledSubmit = useSelector(state => state.Fuel.disabled);
    // Array of fuel types from backend that can be selected based on the currentVehicle.fuel_type
    // Only same fuel category's fuel types are valid options
    const validFuelTypes = fuelCategories.find(c => c.fuel_types.includes(fuel_type))?.fuel_types || [];
    const translatedFuelTypes = validFuelTypes.map(fuel => ({ value: fuel, label: i18n[fuel] || fuel }));
    // Helper fn to modify just the file in the formData
    const setFormDataFile = receipt_large => setFormData({ ...formData, receipt_large });

    const onTakePhoto = async e => {
        const file = e.target.files[0];

        if(!file) return;
        const { name } = file;
        const fileDataURI = await toBase64(file);

        const backendFile = dataURIToBackendFile(fileDataURI);
        setPreview(backendFile);
        const payloadFile = { ...backendFile, name };
        // const objectUrl = file.target.files;
        setFormDataFile({
            ...payloadFile,
            name: `DEFAULT_NAME_${Math.round(Math.random() * 1e7)}.jpg`, // Not t`` because not shown to user
        });
    };

    const onSubmit = e => {
        e.preventDefault();

        const hookFieldsValid = [date, volume, price, totalKm].map(f => f.validate()).every(v => v) &&
                                [volume, price, totalKm].map(validateDecimal).every(v => v);
        const otherFieldsValid = validate(formData, setErrors);

        if(!hookFieldsValid || !otherFieldsValid) {
            return;
        }

        const payload = {
            ...formData,
            // We need to format this to string here, because moment objects when put into state and serializes will
            // fill over 650K that is the state limit, more info:
            // look from stackoverflow
            // Failed to execute 'pushState' on 'History' error when using window.history.pushState function
            date: moment(date.value).utc().format(),
            volume: volume.value,
            price: price.value,
            total_km: totalKm.value ? totalKm.value : undefined,
        };

        if(navigator.onLine) {
            (id ? editFuelRecord(id, payload, rollbackData) : addFuelRecord(payload))
                .then(() => history.push(`/fuel`));
        } else {
            id ? editFuelRecord(id, payload, rollbackData) : addFuelRecord(payload);
            history.push(`/fuel`);
        }
    };

    const onDelete = e => {
        e.preventDefault();

        if(navigator.onLine) deleteFuelRecord(id).then(() => history.push('/fuel'));
        else {
            deleteFuelRecord(id);
            history.replace('/fuel');
        }
    };

    useEffect(() => {
        // Assume fuelCategories don't change. So if they're in redux, don't reGET them
        !fuelCategories.length && getFuelCategories();

        if(id) {
            if(formData.id) { // When navigated from card
                setRollbackData(formData);
                [volume, price, totalKm].forEach(f => f.setValue(formData[f.inputProps.name]));
                setPreview(formData.receipt_large);
                date.setValue(moment(formData.date).format('YYYY-MM-DDTHH:mm'));
            } else {
                setDisableFieldset(true);
                getSingleFuelRecord(id).then(res => {
                    if(res.error) {
                        console.warn('Couldn\'t get fuel record for id', id, '\nResponse:', res);
                        history.push('/fuel');
                    } else if(refMounted.current) {
                        setFormData({ ...res, car_id });
                        [volume, price, totalKm].forEach(f => f.setValue(res[f.inputProps.name]));
                        setPreview(res.receipt_large);
                        date.setValue(moment(res.date).format('YYYY-MM-DDTHH:mm'));
                        setDisableFieldset(false);
                    }
                });
            }
        }
    }, []);

    return (
        <section id='fuel-form' className={formData.receipt_large ? 'has-image' : ''}>
            <TitleContainer path='/fuel' title={id ? t`EDIT_FUEL_RECORD` : t`ADD_FUEL_RECORD`} />
            <form onSubmit={onSubmit}>
                <fieldset className='fieldset' disabled={disableFieldset}>
                    <Input
                        accept={'image/*'}
                        type='file'
                        label={t`RECEIPT_IMAGE`}
                        onChange={e => onTakePhoto(e)}
                        className='fuel-image-changer'
                        error={errors.receipt_large}
                    >
                        {preview ? (
                            <img src={backendFileToDataURI(preview)} alt={t`CHANGE_RECEIPT_PHOTO`} />
                        ) : <span>{t`CLICK_TO_ADD_PICTURE`}</span>}
                    </Input>
                    <Input {...date.inputProps} />
                    <Dropdown
                        label={t`FUEL_TYPE`}
                        options={translatedFuelTypes}
                        value={formData.fuel_type}
                        onChange={fuel_type => setFormData({ ...formData, fuel_type })}
                    />
                    <Input {...volume.inputProps} />
                    <Input {...price.inputProps} />
                    <Input {...totalKm.inputProps} />
                </fieldset>
                <input type='submit' value={t`SAVE`} className='button button--primary' disabled={disabledSubmit} />
                {!!id && (
                    <button type='button' className='button button--secondary' onClick={() => setPopupVisible(true)}>
                        {t`DELETE`}
                    </button>
                )}
            </form>
            <PopupModal
                visible={popupVisible}
                title={t`CONFIRM_HEADER`}
                body={t`CONFIRM_REFUEL_DELETE`}
                onCancel={() => setPopupVisible(false)}
                onConfirm={onDelete}
            />
        </section>
    );
};

const mapStateToProps = state => ({
    currentVehicle: state.SelectVehicle.currentVehicle,
    fuelCategories: state.Fuel.fuelCategories,
});

const mapDispatchToProps = { addFuelRecord, editFuelRecord, getSingleFuelRecord, getFuelCategories, deleteFuelRecord };

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditFuel);
