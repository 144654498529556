import {
    REQUEST,
    FAILURE,
    GET_FUEL_HISTORY,
    GET_FUEL_CATEGORIES,
    DELETE_FUEL_RECORD_REQUEST,
    DELETE_FUEL_RECORD,
    ADD_FUEL_RECORD,
    EDIT_FUEL_RECORD,
    ADD_FUEL_RECORD_REQUEST,
    ADD_FUEL_RECORD_FAILURE,
    EDIT_FUEL_RECORD_FAILURE,
    EDIT_FUEL_RECORD_REQUEST,
} from 'components/Fuel/actions.js';
import { REMOVE_CURRENT_VEHICLE } from 'components/SelectVehicle/actions.js';

export const DELETED = Symbol.for('DELETED');

const initialState = {
    error: null,
    fuelHistory: [],
    fuelCategories: [],
    disabled: false,
};

const fuelSort = (a, b) => new Date(b.date) - new Date(a.date);

export default function fuel(state = initialState, action) {
    switch(action.type) {
        case REQUEST: { return state; }

        case FAILURE: {
            return {
                ...state,
                error: action.error,
            };
        }

        case GET_FUEL_HISTORY: {
            const fuelHistory = action.response.sort((a, b) => new Date(b.date) - new Date(a.date));

            return { ...state, fuelHistory };
        }

        case GET_FUEL_CATEGORIES: {
            return {
                ...state,
                fuelCategories: action.response.fuel_categories || [],
            };
        }

        case ADD_FUEL_RECORD_REQUEST: {
            const { body, PENDING } = action.meta;
            body[PENDING] = true;
            const fuelHistory = [...state.fuelHistory, body];

            return {
                ...state,
                disabled: true,
                fuelHistory: fuelHistory.sort(fuelSort),
            };
        }

        case ADD_FUEL_RECORD: {
            const { PENDING } = action.meta;
            const newHistory = state.fuelHistory.filter(fuel => !fuel[PENDING]);
            const fuelHistory = [action.payload, ...newHistory];

            return {
                ...state,
                disabled: false,
                fuelHistory: fuelHistory.sort(fuelSort),
            };
        }

        case ADD_FUEL_RECORD_FAILURE: {
            const { PENDING } = action.meta;
            const fuelHistory = state.fuelHistory.filter(fuel => !fuel[PENDING]);

            return {
                ...state,
                disabled: false,
                fuelHistory: fuelHistory.sort(fuelSort),
                error: action.error,
            };
        }

        case EDIT_FUEL_RECORD_REQUEST: {
            const { body, PENDING } = action.meta;
            const fuelHistory = [...state.fuelHistory];
            body[PENDING] = true;

            fuelHistory.splice(fuelHistory.findIndex(record => record.id === body.id), 1, body);

            return {
                ...state,
                fuelHistory: fuelHistory.sort(fuelSort),
            };
        }

        case EDIT_FUEL_RECORD: {
            const { payload } = action;
            const { PENDING } = action.meta;
            const { fuelHistory } = state;
            const index = fuelHistory.findIndex(record => record[PENDING]);
            // We need to spread the old one, and override the old data with new.
            // VERY IMPORTANT because otherwise we don't get the PENDING Symbols with if one object has multiple ones
            const newFuel = { ...fuelHistory[index], ...payload };

            // Need to delete the assigned PENDING symbol
            delete newFuel[PENDING];
            fuelHistory.splice(index, 1, newFuel);
            return {
                ...state,
                fuelHistory: fuelHistory.sort(fuelSort),
            };
        }

        case EDIT_FUEL_RECORD_FAILURE: {
            const { PENDING, rollbackData } = action.meta;
            const fuelHistory = state.fuelHistory.filter(fuel => !fuel[PENDING]);
            fuelHistory.splice(fuelHistory.findIndex(record => record.id === rollbackData.id), 1, rollbackData);

            return {
                ...state,
                fuelHistory: fuelHistory.sort(fuelSort),
                error: action.error,
            };
        }

        case DELETE_FUEL_RECORD_REQUEST: {
            const id = parseInt(action.meta.id, 10);
            const fuelHistory = [...state.fuelHistory];

            fuelHistory.find(record => record.id === id)[DELETED] = true;

            return { ...state, fuelHistory };
        }

        case DELETE_FUEL_RECORD: {
            const fuelHistory = [...state.fuelHistory].filter(record => !record[DELETED]);
            return { ...state, fuelHistory };
        }

        case REMOVE_CURRENT_VEHICLE: {
            // When vehicle changes, clear fuelHistory, as it's only relevant for currentVehicle
            return {
                ...state,
                fuelHistory: [],
            };
        }

        default: { return state; }
    }
}
