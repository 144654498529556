import { useState, useRef, useEffect } from 'react';
import { t } from 'ttag';
import CameraPhoto, { IMAGE_TYPES, FACING_MODES } from 'jslib-html5-camera-photo';
import CameraView from 'components/common/CameraView/index.js';
import { toBase64 } from 'utils.js';
import './main.scss';

const dataURIToBackendFile = str => ({
    content_base64: str.slice(str.indexOf(',') + 1),
    content_type: str.slice(str.indexOf(':') + 1, str.indexOf(';')),
});

const CAMERA_CONFIG = { sizeFactor: 1, imageType: IMAGE_TYPES.JPG };

const setupCamera = (refCamera, refVideo) => {
    refCamera.current = new CameraPhoto(refVideo.current);
    refCamera.current.startCamera(FACING_MODES.ENVIRONMENT)
        .catch(err => console.error('Camera couldn\'t be started.\n', err));
};

const JSLibCameraView = ({ className, onCancel, onTakePhoto, onFileChoose, maxSize }) => {
    const refVideo = useRef(null);
    const refCamera = useRef(null);
    const refFileInput = useRef(null);
    const [error, setError] = useState(null);

    const handleTakePhoto = () => {
        const photoDataURI = refCamera.current.getDataUri(CAMERA_CONFIG);
        const resultingFile = dataURIToBackendFile(photoDataURI);

        if(maxSize && (resultingFile.content_base64.length > maxSize)) {
            const MAX_SIZE = +parseFloat(maxSize / 1370000).toFixed(1);
            setError(t`FILE_TOO_BIG_MAX_${MAX_SIZE}_MB`);
            onTakePhoto(null);
        } else {
            setError(null);
            onTakePhoto(resultingFile);
        }

        refFileInput.current.value = '';
        refCamera.current.stopCamera().catch(console.warn);
    };

    // Handles only max. 1 (first) file
    const handleFileChoose = async files => {
        if(!files.length) {
            onFileChoose(null);
            return;
        }

        const [file] = files;
        const { name } = file;
        const fileDataURI = await toBase64(file);

        if(maxSize && (fileDataURI.length > maxSize)) {
            const MAX_SIZE = +parseFloat(maxSize / 1370000).toFixed(1);
            setError(t`FILE_TOO_BIG_MAX_${MAX_SIZE}_MB`);
            onFileChoose(null);
        } else {
            setError(null);
            onFileChoose({ ...dataURIToBackendFile(fileDataURI), name });
        }
    };

    useEffect(() => {
        setupCamera(refCamera, refVideo);

        return () => refCamera.current.stopCamera().catch(console.warn);
    }, []);

    return (
        <CameraView
            className={`jslib-camera-view ${className}`}
            component={(
                <>
                    { /* eslint-disable-next-line jsx-a11y/media-has-caption */ }
                    <video
                        ref={refVideo}
                        autoPlay
                    />
                    <span className={`error ${error ? 'has-error' : ''}`}>
                        {error || ' '}
                    </span>
                </>
            )}
            text={t`CAMERA_INFO_TEXT`}
            onCancel={onCancel}
            onTakePhoto={handleTakePhoto}
            onFileChoose={handleFileChoose}
            refFileInput={refFileInput}
        />
    );
};

export default JSLibCameraView;
