import {
    SHOW_SNACKBAR,
    HIDE_SNACKBAR,
    SHOW_NOTIFICATION_MODAL,
    HIDE_NOTIFICATION_MODAL,
    UPDATE_SW,
} from './actions';

const initialState = {
    snackbar: {
        text: null,
        type: null,
        visible: false,
    },
    showMenu: true,
    notificationModal: {
        open: false,
        header: '',
        body: '',
        onConfirm: null,
        confirmLabel: '',
        update: false,
    },
};

let resetTimeout;

export default function layout(state = initialState, action) {
    switch(action.type) {
        case SHOW_SNACKBAR: {
            resetTimeout && clearTimeout(resetTimeout);
            resetTimeout = setTimeout(() => action.asyncDispatch({ type: 'HIDE_SNACKBAR' }), 5000);

            return {
                ...state,
                snackbar: {
                    text: action.text,
                    type: action.notificationType,
                    visible: true,
                },
                wasOffline: !!action.wasOffline,
            };
        }
        case HIDE_SNACKBAR: {
            resetTimeout && clearTimeout(resetTimeout);
            return {
                ...state,
                snackbar: { ...state.snackbar, visible: false },
            };
        }
        case SHOW_NOTIFICATION_MODAL: {
            const { title, body, options } = action;

            return {
                ...state,
                notificationModal: {
                    open: true,
                    title,
                    body,
                    options,
                    update: state.notificationModal.update,
                },
            };
        }
        case HIDE_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModal: {
                    ...state.notificationModal,
                    open: false,
                },
            };
        }
        // added here, thought no css, but yes css :D
        case UPDATE_SW: {
            return {
                ...state,
                notificationModal: {
                    ...state.notificationModal,
                    update: true,
                },
            };
        }
        default: {
            return state;
        }
    }
}
