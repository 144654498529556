import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'ttag';
import moment from 'moment';
import CardWithImage from 'components/common/CardWithImage/index.js';
import HistoryViewContainer from 'components/common/HistoryViewContainer/index.js';
import { addDividerIfNeeded } from 'components/common/ListViewContainer/utils.js';
import { ReactComponent as IconCamera } from 'static/Common/icon-camera.svg';
import { getFuelHistory } from 'components/Fuel/actions.js';
import { DELETED } from 'components/Fuel/reducers.js';
import { backendFileToDataURI } from 'utils.js';
import './main.scss';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector.js';

const Fuel = ({ getFuelHistory, currentVehicle, fuelHistory }) => {
    useEffect(() => { getFuelHistory(currentVehicle.id); }, []);

    const fuelButton = {
        icon: <IconCamera />,
        text: t`CLICK_TO_SCAN_RECEIPT`,
        link: '/fuel/new',
    };
    const user = useShallowEqualSelector(state => state.Auth.user);

    return (
        <HistoryViewContainer
            id='fuel'
            title={t`FUEL`}
            button={fuelButton}
            history={fuelHistory.map((fuelRecord, i) => {
                const { id, fuel_type, volume, date, receipt_small } = fuelRecord;
                const thisUser = user.id === fuelRecord.user?.id;

                return (
                    <React.Fragment key={i}>
                        <li className={fuelRecord[DELETED] ? 'deleted' : undefined}>
                            <CardWithImage
                                to={thisUser ? { pathname: `/fuel/${id}`, state: fuelRecord } : undefined}
                                label={`${fuel_type} ${volume} L`}
                                secondary={moment(date).format('L')}
                                src={backendFileToDataURI(receipt_small)}
                                alt={t`RECEIPT`}
                            />
                        </li>
                        {addDividerIfNeeded(fuelRecord, fuelHistory[i + 1], 'date')}
                    </React.Fragment>
                );
            })}
        />
    );
};

const mapStateToProps = state => ({
    fuelHistory: state.Fuel.fuelHistory,
    currentVehicle: state.SelectVehicle.currentVehicle,
});

export default connect(mapStateToProps, { getFuelHistory })(Fuel);
