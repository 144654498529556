import { useState, useRef, useEffect, useCallback } from 'react';
import { t } from 'ttag';
import { ReactComponent as IconArrow } from 'static/Common/icon-arrow-down.svg';
import { ReactComponent as IconTick } from 'static/Common/icon-tick.svg';
import './main.scss';

const Dropdown = ({
    options, //        = []        An array of objects, in the form [{ label: *, value: string }, ...]
    onChange, //       = fn()      Called on item select. Receives options[i].value, or if it's undefined, selectedIndex
    label, //          = String    Rendered next to the button                         - default: null
    defaultValue, //   = Number    Sets the initial selected value for the dropdown    - default: undefined
    noTail, //         = Boolean   Determines if the dropdown list has a tail above it - default: false
    highlightColor, // = String    CSS <color> for the selected item                   - default: 'var(--accent-yellow)'
    compact, //        = Boolean   Sets a compact padding for the dropdown             - default: false
    noTick, //         = Boolean   Toggles the tick icon next to the selected item     - default: false
    noBackdrop, //     = Boolean   Toggle for the dark background on dropdown open     - default: false
    alignLeft, //      = Boolean   Aligns the dropdown list to left instead of right   - default: false
    alignTicksLeft, // = Boolean   Aligns the tick icons to the left instead of right  - default: false
    altOpen, //        = boolean   Pass this and setAltOpen if you want to control
    //                             the open-state externally                           - default: undefined
    setAltOpen, //     = fn()                                                          - default: undefined
    value, //          = *         If defined, the dropdown's value is set by this     - default: undefined
    listRef, //        = ref       A ref of the dropdown <ul> is passed to this        - default: undefined
    className = '',
    disabled,
}) => {
    const initialValue = value === undefined ? defaultValue : value;
    const [stateValue, setStateValue] = useState(initialValue);
    const [focusIndex, setFocusIndex] = useState(null);
    const [open, setOpen] = useState(false);
    const optionRefs = useRef(Array(options.length));
    const refDropdown = useRef(null);

    const isStateValueArray = Array.isArray(stateValue);

    useEffect(() => { (value !== undefined) && setStateValue(value); }, [value, setStateValue]);

    useEffect(() => {
        optionRefs.current = options.map((option, i) => optionRefs.current[i] || null);
        setFocusIndex(null);
    }, [options]);

    const handleKeypress = useCallback(e => {
        const { key } = e;

        const currentFocusIndex = (() => {
            if(focusIndex === null) {
                const indexOfSelected = options.findIndex(option => option.value === stateValue);
                return indexOfSelected === -1 ? 0 : indexOfSelected;
            }

            return focusIndex;
        })();

        switch(key) {
            case 'Escape': {
                setAltOpen && setAltOpen(false);
                closeDropdown();
                break;
            }
            case 'Tab': {
                // Might add additional logic here later
                setAltOpen && setAltOpen(false);
                closeDropdown();
                break;
            }
            case 'ArrowUp': {
                e.preventDefault();
                let newFocusIndex = 0;

                if(!currentFocusIndex && currentFocusIndex !== 0) {
                    newFocusIndex = options.length - 1;
                } else {
                    newFocusIndex = (currentFocusIndex === 0) ? options.length - 1 : currentFocusIndex - 1;
                }

                optionRefs.current[newFocusIndex].focus();
                setFocusIndex(newFocusIndex);
                break;
            }
            case 'ArrowDown': {
                e.preventDefault();
                let newFocusIndex = 0;

                if(!currentFocusIndex && currentFocusIndex !== 0) {
                    newFocusIndex = 0;
                } else {
                    newFocusIndex = (currentFocusIndex === (options.length - 1)) ? 0 : currentFocusIndex + 1;
                }

                optionRefs.current[newFocusIndex].focus();
                setFocusIndex(newFocusIndex);
                break;
            }
            default: { break; }
        }
    });

    const handleClick = useCallback(() => { altOpen === undefined && setOpen(false); }, [setOpen]);

    useEffect(() => {
        if((altOpen !== undefined && altOpen) || open) {
            window.addEventListener('keydown', handleKeypress, false);
            window.addEventListener('click', handleClick, false);
        } else {
            window.removeEventListener('keydown', handleKeypress, false);
            window.removeEventListener('click', handleClick, false);
        }

        return () => {
            window.removeEventListener('keydown', handleKeypress, false);
            window.removeEventListener('click', handleClick, false);
        };
    }, [open, altOpen, handleKeypress, handleClick]);

    const handleSelect = value => {
        if(value?.value !== undefined) value = value.value;

        setStateValue(isStateValueArray ? [...stateValue, value] : value);

        if(typeof onChange === 'function') { onChange(value); }
    };

    const openDropdown = () => { altOpen === undefined && setOpen(true); };

    const closeDropdown = () => {
        if(altOpen === undefined) {
            setOpen(false);
            setFocusIndex(null);
            refDropdown.current.focus();
        }
    };

    const isOpen = (altOpen !== undefined) ? altOpen : open;

    const chosenLabel = (() => {
        const selectedOption = options.find(option => {
            if(option?.value === undefined) return option === stateValue;
            else return option.value === stateValue;
        });

        if(selectedOption === undefined) { return null; }

        return selectedOption?.label || selectedOption;
    })();

    return (
        <div className={`dropdown ${className} ${
            isOpen ? 'open' : ''} ${
            compact ? 'compact' : ''} ${
            alignLeft ? 'left' : ''} ${
            alignTicksLeft ? 'ticks-left' : ''}`}
        >
            <div className={`backdrop ${noBackdrop ? 'no-backdrop' : ''}`} onClick={closeDropdown} />
            <button
                type='button'
                onClick={open ? closeDropdown : openDropdown}
                className='dropdown-handle'
                ref={refDropdown}
                disabled={disabled}
            >
                <span className='dropdown-label'>
                    {label}
                </span>
                <div className='dropdown-toggle'>
                    <span className='chosen' title={chosenLabel}>
                        {chosenLabel || '\u00A0'}
                    </span>
                    <IconArrow alt={isOpen ? t`CLOSE_DROPDOWN` : t`OPEN_DROPDOWN`} />
                </div>
            </button>
            <ul className={`list ${noTail ? 'no-tail' : ''}`} ref={listRef}>
                {options.map((option, i) => {
                    const { value, label, className, disabled } = option;

                    const isSelected = isStateValueArray
                        ? stateValue.includes(value)
                        : (value ?? option) === stateValue;

                    return (
                        <li key={i}>
                            <button
                                type='button'
                                ref={el => optionRefs.current[i] = el}
                                className={`${isSelected ? 'selected' : ''} ${className || ''}`}
                                style={isSelected ? { background: highlightColor } : undefined}
                                onClick={() => {
                                    handleSelect(option);
                                    closeDropdown();
                                }}
                                disabled={disabled}
                            >
                                {label === undefined ? option : label}
                                {!noTick && <IconTick className='tick' />}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Dropdown;
