import { t } from 'ttag';
import { ReactComponent as IconEmissions } from 'static/Common/icon-emissions.svg';
import { ReactComponent as IconShare } from 'static/Common/icon-share.svg';
import { ReactComponent as IconCalendar } from 'static/Common/icon-calendar.svg';
import { ReactComponent as IconHardware } from 'static/Common/icon-hardware.svg';
import { MAX_UPLOAD_BYTES } from 'constants.js';
import { FUEL_TYPES } from 'utils.js';

export const formatCarObject = car => ({
    ...(car.car_data || car.car_spec),
    ...car,
    ...car.car_data?.id && { car_data_id: car.car_data?.id },
    ...car.car_spec?.id && { car_spec_id: car.car_spec?.id },
    shared_to: car.shared_to?.map(i => i.name) || [''],
    sourced_from: car.sourced_from?.map(i => i.name) || [''],
});

export const I18N = () => ({
    // Field names
    nedc_emissions: t`NEDC_G/KM`,
    wltp_emissions: t`WLTP_G/KM`,
    euro_emission_standard: t`EUROPEAN_EMISSIONS_STANDARD`,
    sourced_from: t`SOURCED_FROM`,
    shared_to: t`SHARED_TO`,
    created_at: t`CREATED`,
    next_tech_inspection_start: t`NEXT_TECH_INSPECTION_START`,
    updated_at: t`MODIFIED`,
    last_tech_inspection: t`LAST_TECH_INSPECTION`,
    commissioning_date: t`COMMISSIONING`,
    trafi_commissioning_date: t`TRAFI_COMMISSIONING`,
    first_registration_date: t`FIRST_REGISTRATION`,
    next_tech_inspection_end: t`NEXT_TECH_INSPECTION_END`,
    name: t`NAME`,
    make: t`MAKE`,
    model: t`MODEL`,
    type: t`TYPE`,
    model_year: t`MODEL_YEAR`,
    vehicle_class: t`VEHICLE_CLASS`,
    drive_type: t`DRIVE_TYPE`,
    body_type: t`BODY_TYPE`,
    transmission_type: t`TRANSMISSION_TYPE`,
    engine_volume: t`ENGINE_VOL`,
    max_power: t`MAX_POWER`,
    fuel_type: t`FUEL_TYPE`,
    mileage: t`MILEAGE`,
    base_color: t`BASE_COLOR`,
    alcolock: t`ALCOLOCK`,
    tachograph: t`BASE_TACHOGRAPH`,
    // EURO -classes
    'EURO 1': t`EURO_1`,
    'EURO 2': t`EURO_2`,
    'EURO 3': t`EURO_3`,
    'EURO 4': t`EURO_4`,
    'EURO 5': t`EURO_5`,
    'EURO 5a': t`EURO_5A`,
    'EURO 5b': t`EURO_5B`,
    'EURO 6': t`EURO_6`,
    'EURO 6a': t`EURO_6A`,
    'EURO 6b': t`EURO_6B`,
    'EURO 6c': t`EURO_6C`,
    'EURO 6d': t`EURO_6D`,
    'Electric vehicle': t`ELECTRIC_VEHICLE`,
    LKW: t`LKW`,
    // Drive types
    'All wheel drive': t`ALL_WHEEL_DRIVE`,
    'Front wheel drive': t`FRONT_WHEEL_DRIVE`,
    'Rear wheel drive': t`REAR_WHEEL_DRIVE`,
    // Body types
    'Motor caravan': t`MOTOR_CARAVAN`,
    'Station wagon': t`STATION_WAGON`,
    Sedan: t`SEDAN`,
    'Off-road vehicle': t`OFF-ROAD_VEHICLE`,
    'Exceptional load transport trailer': t`EXCEPTIONAL_LOAD_TRANSPORT_TRAILER`,
    'Sedan/convertible': t`SEDAN/CONVERTIBLE`,
    Notchback: t`NOTCHBACK`,
    'Open roof double deck vehicle': t`OPEN_ROOF_DOUBLE_DECK_VEHICLE`,
    'Special transport trailer': t`SPECIAL_TRANSPORT_TRAILER`,
    'Semi-trailer tractor': t`SEMI-TRAILER_TRACTOR`,
    Hatchback: t`HATCHBACK`,
    'High roof station wagon': t`HIGH_ROOF_STATION_WAGON`,
    Bus: t`BUS`,
    Coupé: t`COUPÉ`,
    'Special transport truck': t`SPECIAL_TRANSPORT_TRUCK`,
    Roadster: t`ROADSTER`,
    Camper: t`CAMPER`,
    'Auxiliary trolley': t`AUXILIARY_TROLLEY`,
    'Semi-trailer': t`SEMI-TRAILER`,
    Monospace: t`MONOSPACE`,
    Transporter: t`TRANSPORTER`,
    'Commercial vehicle': t`COMMERCIAL_VEHICLE`,
    Minivan: t`MINIVAN`,
    'Multi-equipment carrier': t`MULTI-EQUIPMENT_CARRIER`,
    Trailer: t`TRAILER`,
    'Articulated low-floor single-deck': t`ARTICULATED_LOW-FLOOR_SINGLE-DECK`,
    'Chassis cab': t`CHASSIS_CAB`,
    'Other special purpose vehicles': t`OTHER_SPECIAL_PURPOSE_VEHICLES`,
    Hearse: t`HEARSE`,
    Lorry: t`LORRY`,
    Saloon: t`SALOON`,
    Pickup: t`PICKUP`,
    'Articulated single-deck': t`ARTICULATED_SINGLE-DECK`,
    'Low-floor double-deck': t`LOW-FLOOR_DOUBLE-DECK`,
    'Articulated low-floor double-deck': t`ARTICULATED_LOW-FLOOR_DOUBLE-DECK`,
    'Wheelchair accessible vehicle': t`WHEELCHAIR_ACCESSIBLE_VEHICLE`,
    'Trailer with non-articulated drawbar': t`TRAILER_WITH_NON-ARTICULATED_DRAWBAR`,
    'Double-deck': t`DOUBLE-DECK`,
    Caravan: t`CARAVAN`,
    'Multi-purpose vehicle': t`MULTI-PURPOSE_VEHICLE`,
    'Open roof single deck vehicle': t`OPEN_ROOF_SINGLE_DECK_VEHICLE`,
    Van: t`VAN`,
    'Armoured vehicle': t`ARMOURED_VEHICLE`,
    'Single-deck': t`SINGLE-DECK`,
    'Closed body': t`CLOSED_BODY`,
    'Towing vehicle for a trailer': t`TOWING_VEHICLE_FOR_A_TRAILER`,
    'Articulated double-deck': t`ARTICULATED_DOUBLE-DECK`,
    'Mobile crane': t`MOBILE_CRANE`,
    Convertible: t`CONVERTIBLE`,
    SUV: t`SUV`,
    Ambulance: t`AMBULANCE`,
    'Low-floor single-deck': t`LOW-FLOOR_SINGLE-DECK`,
    Cabriolet: t`CABRIOLET`,
    'Exceptional load transport motor vehicle': t`EXCEPTIONAL_LOAD_TRANSPORT_MOTOR_VEHICLE`,
    'Centre-axle trailer': t`CENTRE-AXLE_TRAILER`,
    // Transmission types
    Stepless: t`STEPLESS`,
    'Foot-shifted': t`FOOT-SHIFTED`,
    'Variable transmission': t`VARIABLE_TRANSMISSION`,
    Variator: t`VARIATOR`,
    'APT-S': t`APT-S`,
    'Manual-Automatic': t`MANUAL-AUTOMATIC`,
    'Continuously Variable': t`CONTINUOUSLY_VARIABLE`,
    Manual: t`MANUAL`,
    Automatic: t`AUTOMATIC`,
    // Fuel types
    ...FUEL_TYPES(),
    // Base colors
    Turqoise: t`TURQUOISE`,
    Orange: t`ORANGE`,
    Blue: t`BLUE`,
    Gray: t`GRAY`,
    Green: t`GREEN`,
    Yellow: t`YELLOW`,
    'Brown (beige)': t`BROWN_BEIGE`,
    Red: t`RED`,
    White: t`WHITE`,
    Multicolor: t`MULTICOLOR`,
    Purple: t`PURPLE`,
    Black: t`BLACK`,
    Silver: t`SILVER`,
    // Duplicates
    Other: t`OTHER`,
});

export const FIELDSETS = () => {
    // These are defined here for shorter syntax below
    const WIDE = true;
    const EDITABLE = true;
    const BOOL = true;
    const STEP = 1;

    return [
        {
            label: t`EMISSIONS`,
            icon: <IconEmissions />,
            fields: {
                nedc_emissions: { EDITABLE, STEP, TYPE: 'number', FILE: 'nedc_emissions_file', SOURCE: 'nedc_source' },
                wltp_emissions: { EDITABLE, STEP, TYPE: 'number', FILE: 'wltp_emissions_file', SOURCE: 'wltp_source' },
                euro_emission_standard: { EDITABLE, OPTIONS: 'euro_standards' },
            },
        },
        {
            label: t`SHARING`,
            icon: <IconShare />,
            fields: {
                sourced_from: { WIDE },
                shared_to: { WIDE },
            },
        },
        {
            label: t`DATE_AND_TIME_OF`,
            icon: <IconCalendar />,
            fields: {
                last_tech_inspection: { EDITABLE, TYPE: 'date' },
                next_tech_inspection_start: { EDITABLE, TYPE: 'date' },
                next_tech_inspection_end: { EDITABLE, TYPE: 'date' },
                commissioning_date: { TYPE: 'date' },
                trafi_commissioning_date: { TYPE: 'date' },
                first_registration_date: { TYPE: 'date' },
            },
        },
        {
            label: t`VEHICLE_BASIC_INFO`,
            icon: <IconHardware />,
            fields: {
                make: { WIDE },
                model: { WIDE },
                type: { WIDE },
                model_year: {},
                vehicle_class: {},
                drive_type: { EDITABLE, OPTIONS: 'drive_types' },
                body_type: { EDITABLE, OPTIONS: 'body_types' },
                transmission_type: { EDITABLE, OPTIONS: 'transmission_types' },
                engine_volume: { EDITABLE, TYPE: 'number', STEP },
                max_power: { EDITABLE, TYPE: 'number' },
                fuel_type: { EDITABLE, EDITED: 'fuel_type_edited', OPTIONS: 'fuel_types' },
                mileage: {},
                base_color: { EDITABLE, OPTIONS: 'base_colors' },
                alcolock: { BOOL },
                tachograph: { BOOL },
            },
        },
    ];
};

export const validate = (values, origValues, setErrors) => {
    const errors = {};

    const {
        nedc_emissions,
        nedc_emissions_file,
        wltp_emissions,
        wltp_emissions_file,
        next_tech_inspection_start,
        next_tech_inspection_end,
    } = values;

    Object.keys(values).forEach(field => {
        const value = values[field] === '' ? null : values[field];

        // Don't validate unchanged fields
        if(origValues[field] === value) return;

        const maxBase64UploadSize = MAX_UPLOAD_BYTES * .75;
        const maxBase64UploadStr = `${+(maxBase64UploadSize / 1000000).toFixed(1)}MB`;

        switch(field) {
            case 'nedc_emissions': {
                if(!value) return errors[field] = t`REQUIRED`;

                const parsed = parseFloat(value);
                if(Number.isNaN(parsed) || (parsed < 0)) return errors[field] = t`POSITIVE_NUMBER_REQUIRED`;

                if(value && !nedc_emissions_file) return errors.nedc_emissions_file = t`FILE_REQUIRED`;

                break;
            }

            case 'nedc_emissions_file': {
                if(!value && nedc_emissions) return errors[field] = t`FILE_REQUIRED`;

                if(value && !nedc_emissions) return errors.nedc_emissions = t`REQUIRED_WITH_FILE`;

                if(value.size > maxBase64UploadSize) return errors[field] = t`MAX_FILE_SIZE_${maxBase64UploadStr}`;

                break;
            }

            case 'wltp_emissions': {
                if(!value) return errors[field] = t`REQUIRED`;

                const parsed = parseFloat(value);
                if(Number.isNaN(parsed) || (parsed < 0)) return errors[field] = t`POSITIVE_NUMBER_REQUIRED`;

                if(value && !wltp_emissions_file) return errors.wltp_emissions_file = t`FILE_REQUIRED`;

                break;
            }

            case 'wltp_emissions_file': {
                if(!value && wltp_emissions) return errors[field] = t`FILE_REQUIRED`;

                if(value && !wltp_emissions) return errors.wltp_emissions = t`REQUIRED_WITH_FILE`;

                if(value.size > maxBase64UploadSize) return errors[field] = t`MAX_FILE_SIZE_${maxBase64UploadStr}`;

                break;
            }

            case 'last_tech_inspection': {
                if(!value) return errors[field] = t`REQUIRED`;

                break;
            }

            case 'next_tech_inspection_start': {
                if(!value) return errors[field] = t`REQUIRED`;

                const end = next_tech_inspection_end || origValues.next_tech_inspection_end;

                if(end && ((new Date(value)).getTime() > (new Date(end)).getTime())) {
                    errors[field] = t`END_MUST_BE_LATER_THAN_START`;
                    errors.next_tech_inspection_end = t`END_MUST_BE_LATER_THAN_START`;
                }

                break;
            }
            case 'next_tech_inspection_end': {
                if(!value) return errors[field] = t`REQUIRED`;

                const start = next_tech_inspection_start || origValues.next_tech_inspection_start;

                if(start && ((new Date(value)).getTime() < (new Date(start)).getTime())) {
                    errors[field] = t`END_MUST_BE_LATER_THAN_START`;
                    errors.next_tech_inspection_start = t`END_MUST_BE_LATER_THAN_START`;
                }

                break;
            }

            case 'engine_volume':
            case 'max_power': {
                if(!value) return;

                const parsed = parseFloat(value);
                if(Number.isNaN(parsed) || (parsed <= 0)) return errors[field] = t`POSITIVE_NUMBER_REQUIRED`;

                break;
            }

            default: { break; }
        }
    });

    setErrors(errors);

    return Object.values(errors).every(v => !v);
};
