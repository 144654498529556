import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import TitleContainer from '../../common/TitleContainer';
import CardWithImage from '../../common/CardWithImage';
import './main.scss';
import { findCars } from '../actions';
import tempPlaceholder from '../../SelectVehicle/placeholder-model.png';
import { backendFileToDataURI } from '../../../utils';

const SelectCarBooking = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const { start, end } = params;

    const cars = useSelector(state => state.Booking.cars);

    useEffect(() => {
        const payload = {
            since: moment(start).utc().format('YYYY-MM-DD HH:mm'),
            until: moment(end).utc().format('YYYY-MM-DD HH:mm'),
        };

        dispatch(findCars(payload));
    }, []);

    const onClick = car => {
        history.push(`/booking/new/${start}/${end}/${car.id}`);

    };

    return (
        <section id='select-car-booking'>
            <TitleContainer path='/booking/new' title={t`NEW_BOOKING`} />
            <div className='car-container'>
                {cars && cars.map((car, i) => (
                    <CardWithImage
                        key={i}
                        onClick={() => onClick(car)}
                        src={car.thumbnail_small ? backendFileToDataURI(car.thumbnail_small) : tempPlaceholder}
                        label={car?.car_data?.plate || t`N/A`}
                        // secondary={(
                        //     <>
                        //         <span>
                        //             {mileage_timestamp ? formatDateTime(mileage_timestamp).date : null}
                        //         </span>
                        //         <span>
                        //             {roundNumeric(mileage || 0)}{t`KM`}
                        //         </span>
                        //     </>
                        // )}

                    />
                ))}
            </div>

        </section>
    );
};
export default SelectCarBooking;
