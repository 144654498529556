import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_CAR_HOME_DATA = 'GET_CAR_HOME_DATA';
export const GET_CAR_BOOKINGS = 'GET_CAR_BOOKINGS';
export const SET_CAR_IMAGE = 'SET_CAR_IMAGE';

export function getCarHomeData(id) {
    return {
        type: REQUEST,
        meta: {
            offline: {
                effect: {
                    method: 'GET',
                    endpoint: API.dashboard.replace(':id', id),
                },
                commit: { type: GET_CAR_HOME_DATA },
                rollback: { type: FAILURE },
            },
        },
    };
}

export function setCarImage(id, file) {
    const thumbnail_large = {
        ...file,
        name: 'car_image.jpg',
    };

    return {
        type: REQUEST,
        meta: {
            offline: {
                effect: {
                    method: 'PUT',
                    endpoint: API.cars.single.replace(':id', id),
                    body: { thumbnail_large },
                },
                commit: { type: SET_CAR_IMAGE },
                rollback: { type: FAILURE },
            },
        },
    };
}

export function getCarBookings(id) {
    return {
        [CALL_API]: {
            endpoint: API.cars.booking.replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_CAR_BOOKINGS, FAILURE],
        },

    };
}
