import {
    GET_PROFILE,
    UPDATE_PROFILE,
    SHOW_ERROR,
} from './actions';

const initialState = {
    profile: {},
};

export default function settings(state = initialState, action) {
    switch(action.type) {
        case GET_PROFILE: {
            return {
                ...state,
                profile: action.response,
            };
        }
        case UPDATE_PROFILE: {
            return {
                ...state,
                profile: action.payload,
            };
        }
        case SHOW_ERROR: {
            return {
                ...state,
                errorCode: action.errorCode,
            };
        }
        default: {
            return state;
        }
    }
}
