import { useState, useEffect } from 'react';
import { t } from 'ttag';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'components/common/Dropdown/index.js';
import Header from 'components/common/Header';
import { getJourneyCustomers } from 'components/Journey/actions.js';
import { SORTS } from 'components/Journey/utils.js';
import ListItem from './ListItem/index.js';
import Modal from './Modal/index.js';
import './main.scss';

let hInterval = null;
let mounted = false;
const Journey = () => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.SelectVehicle.currentVehicle?.id);
    const plate = useSelector(state => state.SelectVehicle.currentVehicle?.car_data.plate);
    const organizationID = useSelector(state => state.Auth.organizationID);
    const companyID = useSelector(state => state.Auth.companyID);
    const customers = useSelector(state => state.Journey.customers);
    const [selected, setSelected] = useState(false);
    const [sort, setSort] = useState('name');
    const [running, setRunning] = useState({});

    const runningCount = Object.values(running).filter(v => v).length;

    useEffect(() => {
        mounted = true;

        dispatch(getJourneyCustomers(id)).then(res => {
            if(!res || res.error) return console.error('Error getting journey customer data');

            if(res.some(v => v.since_now)) {
                const runningObject = res
                    .filter(v => v.since_now || v.mileage?.since_now)
                    .reduce((acc, cur) => {
                        const { organization_id, company_id, since_now, mileage } = cur;
                        acc[`${organization_id || 0}.${company_id || 0}`] = since_now || mileage?.since_now;
                        return acc;
                    }, {});

                setRunning(runningObject);
                hInterval = setInterval(() => mounted && setRunning(oldRunning => {
                    const newRunning = Object.entries(oldRunning).map(([k, v]) => [k, v ? v + 1000 : 0]);
                    return Object.fromEntries(newRunning);
                }), 1000);
            }
        });

        return () => {
            clearInterval(hInterval);
            hInterval = null;
            mounted = false;
        };
    }, []);

    const sorts = SORTS();
    const isSelf = customer => customer.organization_id === organizationID && customer.company_id === companyID;
    const selfCustomer = customers.find(isSelf);

    const sorted = customers
        .filter(customer => !isSelf(customer))
        .sort(sorts.find(({ value }) => value === sort).fn);

    const onToggle = (orgID, compID, starting) => {
        setRunning(oldRunning => ({ ...oldRunning, [`${orgID || 0}.${compID || 0}`]: +starting }));

        if(!hInterval && starting) {
            hInterval = setInterval(() => mounted && setRunning(oldRunning => {
                const newRunning = Object.entries(oldRunning).map(([k, v]) => [k, v ? v + 1000 : 0]);
                return Object.fromEntries(newRunning);
            }), 1000);
        } else if(!starting && runningCount <= 1) {
            clearInterval(hInterval);
            hInterval = null;
        }
    };

    return (
        <section id='journey'>
            <Header plate={plate} />
            <div className='content'>
                <span className='title'>
                    {t`SELECT_CUSTOMER_TO_DRIVE_FOR`}
                </span>
                <Dropdown options={sorts} value={sort} label={`${t`SORT_BY`}:`} onChange={setSort} noBackdrop noTail />
                {!!runningCount && <span className='approximates-disclaimer'>{t`(DURATIONS_ARE_APPROXIMATES)`}</span>}
                <ol>
                    <ListItem
                        customer={selfCustomer}
                        setCustomer={setSelected}
                        forceName={organizationID ? t`DRIVE_FOR_OWN_ORGANIZATION` : t`DRIVE_FOR_OWN_COMPANY`}
                        onToggle={onToggle}
                        deltas={running}
                    />
                    {sorted.map((customer, i) => (
                        <ListItem
                            key={i}
                            customer={customer}
                            setCustomer={setSelected}
                            onToggle={onToggle}
                            deltas={running}
                        />
                    ))}
                </ol>
            </div>
            <Modal close={() => setSelected(null)} customer={selected} onToggle={onToggle} />
        </section>
    );
};

export default Journey;
