import { t } from 'ttag';
import './main.scss';

const CameraView = ({
    className = '',
    component,
    text,
    onTakePhoto,
    takePhotoLabel,
    onFileChoose,
    chooseFileLabel,
    refFileInput,
    onCancel,
    cancelLabel,
    ...rest
}) => (
    <section className={`camera-view ${className}`} {...rest}>
        {component}
        <div className='camera-view-actions'>
            {text && (
                <p>
                    {text}
                </p>
            )}
            {onTakePhoto && (
                <button type='button' className='button button--primary' onClick={onTakePhoto}>
                    {takePhotoLabel || t`TAKE_PHOTO`}
                </button>
            )}
            {onFileChoose && (
                <label className='button button--primary'>
                    <input ref={refFileInput} type='file' onInput={e => onFileChoose(e.target.files)} />
                    {chooseFileLabel || t`CHOOSE_FILE`}
                </label>
            )}
            <button type='button' className='button button--secondary' onClick={onCancel}>
                {cancelLabel || t`CANCEL`}
            </button>
        </div>
    </section>
);

export default CameraView;
