import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconType } from 'static/Common/icon-type.svg';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector.js';
import './main.scss';
import ListViewContainer from '../common/ListViewContainer';
import CardWithImage from '../common/CardWithImage';
import { backendFileToDataURI, formatDateTime } from '../../utils';
import tempPlaceholder from '../SelectVehicle/placeholder-model.png';
import { getBookingList } from './actions';

const Booking = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const bookings = useShallowEqualSelector(state => state.Booking.bookings);

    useEffect(() => dispatch(getBookingList()), []);

    const bookingButton = {
        icon: <IconType />,
        text: t`ADD_BOOKING_BUTTON`,
        link: '/booking/new',
    };

    const onClick = id => {
        history.push(`/booking/${id}`);
    };

    return (
        <ListViewContainer
            id='booking'
            title={t`BOOKING_TITLE`}
            button={bookingButton}
            noItemsText={t`NO_BOOKINGS_DONE`}
            listTitle={t`MY_BOOKINGS`}
            history={bookings.map(({ since, until, car, id }, i) => (
                <CardWithImage
                    key={i}
                    onClick={() => onClick(id)}
                    src={car.thumbnail_small ? backendFileToDataURI(car.thumbnail_small) : tempPlaceholder}
                    label={car?.car_data?.plate || t`N/A`}
                    secondary={(
                        <>
                            <span>
                                {t`START_TIME`}: {formatDateTime(since).full}
                            </span>
                            <span>
                                {t`END_TIME`}: {formatDateTime(until).full}
                            </span>
                        </>
                    )}

                />

            ))}
        />
    );
};

export default Booking;
