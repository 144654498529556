import { t } from 'ttag';
import ListViewContainer from '../ListViewContainer';

const HistoryViewContainer = ({
    className = '',
    title,
    button,
    history,
    ...rest
}) => (
    <ListViewContainer
        className={className}
        title={title}
        button={button}
        history={history}
        noItemsText={t`HISTORY_WILL_BE_LISTED_HERE`}
        listTitle={t`HISTORY`}
        {...rest}
    />
);

export default HistoryViewContainer;
