import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_BOOKING_LIST = 'GET_BOOKING_LIST';
export const CREATE_NEW_BOOKING = 'CREATE_NEW_BOOKING';
export const FIND_SUITABLE_CARS = 'FIND_SUITABLE_CARS';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const GET_SINGLE_BOOKING = 'GET_SINGLE_BOOKING';

export function getBookingList() {
    return {
        [CALL_API]: {
            endpoint: API.booking.list,
            method: 'GET',
            types: [REQUEST, GET_BOOKING_LIST, FAILURE],
        },
    };
}

export function createBooking(body) {
    return {
        [CALL_API]: {
            endpoint: API.booking.book,
            method: 'POST',
            types: [REQUEST, CREATE_NEW_BOOKING, FAILURE],
            body,
        },
    };
}

export function findBooking(id) {
    return {
        [CALL_API]: {
            endpoint: API.booking.bookId.replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_SINGLE_BOOKING, FAILURE],
        },
    };
}

export function deleteBooking(id) {
    return {
        [CALL_API]: {
            endpoint: API.booking.bookId.replace(':id', id),
            method: 'DELETE',
            types: [REQUEST, DELETE_BOOKING, FAILURE],
            meta: { id },
        },
    };
}

export function findCars(body) {
    return {
        [CALL_API]: {
            endpoint: API.booking.cars,
            method: 'POST',
            types: [REQUEST, FIND_SUITABLE_CARS, FAILURE],
            body,
        },
    };
}
