import moment from 'moment';
import { t } from 'ttag';

let lastDivider = null;
export const addDividerIfNeeded = (current, next, dateKey) => {
    if(!next) return null;

    const cM = moment(current[dateKey]);
    const nM = moment(next[dateKey]);

    if(!nM.isValid()) {
        if(lastDivider === t`UNKNOWN`) return null;

        lastDivider = t`UNKNOWN`;

        return <li className='date-divider'>{lastDivider}</li>;
    }

    const cYear = cM.year();
    const nYear = nM.year();

    const nFormatted = nM.format('L');

    if(lastDivider === nFormatted) return null;

    lastDivider = nFormatted;

    if(cYear !== nYear) return <li className='date-divider'>{nFormatted}</li>;

    const cMonth = cM.month() + 1;
    const nMonth = nM.month() + 1;

    if(cMonth !== nMonth) return <li className='date-divider'>{nFormatted}</li>;

    const cDay = cM.date();
    const nDay = nM.date();

    if(cDay !== nDay) return <li className='date-divider'>{nFormatted}</li>;

    return null;
};
