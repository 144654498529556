import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { t } from 'ttag';
import moment from 'moment';
import { ReactComponent as IconEmissions } from 'static/Common/icon-emissions.svg';
import Header from 'components/common/Header';
import { ReactComponent as IconVehicle } from 'static/Common/icon-vehicle.svg';
import { ReactComponent as IconMileage } from 'static/Common/icon-mileage.svg';
import { ReactComponent as IconFuel } from 'static/Common/icon-fuel.svg';
import { ReactComponent as IconAdd } from 'static/Common/icon-add.svg';
import { ReactComponent as IconCalendar } from 'static/Common/calendar.svg';
import { getCarHomeData, setCarImage, getCarBookings } from 'components/Home/actions.js';
import { backendFileToDataURI, formatDateTime, roundNumeric } from 'utils.js';
import JSLibCameraView from 'components/common/JSLibCameraView/index.js';
import tempPlaceholder from '../SelectVehicle/placeholder-model.png';
import './main.scss';

const PLACEHOLDER_IMAGE_MOCK_FILE = {
    content_type: 'image/png',
    content_base64: tempPlaceholder.slice(22),
};

const isMissingValue = v => [null, undefined, ''].includes(v) || Number.isNaN(v);

const DataSquare = ({ label, date, value }) => (
    <div className='info-square'>
        <div>
            <span className='info-square-label'>{label}</span>
            <span className='info-square-date'>{date}</span>
        </div>
        <span className='info-square-value'>{value}</span>
    </div>
);

const DataCard = ({ path, title, icon, date, latest, average }) => (
    <Link to={path} className='data-card'>
        <div className='info-square data-card-header'>
            <div className='data-card-header-inner'>
                {icon}
                <span className='data-card-title'>
                    {title}
                </span>
            </div>
        </div>
        {!isMissingValue(latest) ? <DataSquare label={t`LATEST_ON_LABEL`} date={date} value={latest} /> : null}
        {!isMissingValue(average)
            ? <DataSquare label={t`AVERAGE_IN_LABEL`} date={t`LAST_WEEK_LABEL`} value={average} />
            : null}
    </Link>
);

const Home = ({
    currentVehicle,
    getCarHomeData,
    mileage,
    refuel,
    booking,
    emission_average,
    consumption_average,
    setCarImage,
    getCarBookings,
}) => {
    const { id, car_data, thumbnail_large } = currentVehicle;
    const { since, until, period_km, total_km_stop } = mileage;
    const { date, volume, total_km: fuel_km } = refuel;

    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [renderedImage, setRenderedImage] = useState(PLACEHOLDER_IMAGE_MOCK_FILE);

    let totalKm = null;
    if(total_km_stop && fuel_km) totalKm = moment(date).isBefore(until) ? total_km_stop : fuel_km;
    else if(total_km_stop) totalKm = total_km_stop;
    else if(fuel_km) totalKm = fuel_km;

    useEffect(() => {
        getCarHomeData(id);
        getCarBookings(id);
    }, []);
    useEffect(() => { thumbnail_large && setRenderedImage(thumbnail_large); }, [currentVehicle]);

    const updateCarImage = file => {
        if(!file) { return; }

        setRenderedImage(file);
        setCarImage(id, file);
        setIsCameraOpen(false);
    };

    if(isCameraOpen) {
        return (
            <JSLibCameraView
                onTakePhoto={updateCarImage}
                onFileChoose={updateCarImage}
                onCancel={() => setIsCameraOpen(false)}
                maxSize='9990000'
            />
        );
    }

    return (
        <div id='home'>
            <Header plate={car_data.plate} />
            <button type='button' className='car-image-btn' onClick={() => setIsCameraOpen(true)}>
                <img src={backendFileToDataURI(renderedImage)} alt={t`VEHICLE`} className='selected-vehicle-image' />
                <IconAdd />
            </button>
            <Link to='/journey' className='data-card driving-button'>
                {t`START_/_END_DRIVING`}
            </Link>
            <Link to='/vehicle' className='data-card vehicle-data'>
                <div className='info-square data-card-header'>
                    <div className='data-card-header-inner'>
                        <IconVehicle />
                        <span className='data-card-title'>
                            {t`VEHICLE_DATA_CARD_TITLE`}
                        </span>
                        <span>
                            {totalKm ? `${totalKm}km` : null}
                        </span>
                    </div>
                </div>
                <div className='info-square' />
                <div className='info-square'>
                    <div className='info-content'>
                        <span>{t`VEHICLE_CARD_EMISSIONS`}</span>
                        <span>{t`VEHICLE_CARD_SHARED`}</span>
                        <span>{t`VEHICLE_CARD_DATE_TIME`}</span>
                        <span>{t`VEHICLE_CARD_BASIC_INFO`}</span>
                    </div>
                </div>
            </Link>
            <DataCard
                path='/mileage'
                title={t`MILEAGE_CARD_TITLE`}
                icon={<IconMileage />}
                date={`${since ? formatDateTime(since).full : ''} - ${until ? formatDateTime(until).full : ''}`}
                latest={isMissingValue(period_km) ? null : `${period_km}km`}
            />
            <DataCard
                path='/fuel'
                title={t`FUEL_CARD_TITLE`}
                icon={<IconFuel />}
                date={date ? formatDateTime(date).full : null}
                latest={isMissingValue(volume) ? null : `${+parseFloat(volume).toFixed(2)}L`}
            />
            <Link to={`/bookings/${id}`} className='data-card vehicle-data'>
                <div className='info-square data-card-header'>
                    <div className='data-card-header-inner'>
                        <IconCalendar />
                        <span className='data-card-title'>
                            {t`BOOKINGS_CARD_TITLE`}
                        </span>
                        <span>
                            {totalKm ? `${totalKm}km` : null}
                        </span>
                    </div>
                </div>
                <div className='info-square' />
                <div className='info-square'>
                    <div className='info-content'>
                        <span>{t`NEXT_BOOKING`}</span>
                        <span>{t`USER`}: {booking?.driver?.username || '---'}</span>
                        <span>{t`START_TIME`}: {booking ? formatDateTime(booking?.since).full : '---'}</span>
                        <span>{t`END_TIME`}: {booking ? formatDateTime(booking?.until).full : '---'}</span>
                    </div>
                </div>
            </Link>

            <div className='data-card'>
                <div className='info-square data-card-header'>
                    <div className='data-card-header-inner'>
                        <IconEmissions />
                        <span className='data-card-title'>
                            {t`INFO`}
                        </span>
                    </div>
                </div>
                <div className='info-square'>
                    <span className='info-square-value-alternate'>{roundNumeric(consumption_average) || '--'}</span>
                    <span className='info-square-unit'>{t`L_/_100KM`}</span>
                </div>
                <div className='info-square'>
                    <span className='info-square-value-alternate'>{roundNumeric(emission_average / 100) || '--'}</span>
                    <span className='info-square-unit'>{t`CO2_G_/_KM`}</span>
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = state => ({
    currentVehicle: state.SelectVehicle.currentVehicle,
    ...state.Home,
});

export default withRouter(connect(mapStateToProps, { getCarHomeData, setCarImage, getCarBookings })(Home));
