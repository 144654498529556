import axios from 'axios';
import { t } from 'ttag';
import { showLoading as showLoadingBar, hideLoading } from 'react-redux-loading-bar';
import { API } from '../constants';
import { ErrorMessages, SuccessMessages } from './messages';

const OFFLINE_STATUS_CHANGED = 'Offline/STATUS_CHANGED';
export const CALL_API = Symbol('CALL API');
/* eslint-disable import/no-mutable-exports */
export let isRefreshing = false;
export let apiQueue = [];
/* eslint-enable import/no-mutable-exports */

function removeLocalStorageKeys() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('public_key');
    localStorage.removeItem('oauth2');
    localStorage.removeItem('user');
    window.location.reload();
}

const processQueue = (error, token = null) => {
    apiQueue.forEach(prom => (error ? prom.reject(error) : prom.resolve(token)));
    apiQueue = [];
};

axios.interceptors.response.use(res => res, err => {
    const { config, response } = err;

    if(!response) {
        return Promise.reject(err);
    } else {
        const { status, data } = response;
        const { retry, headers } = config;

        // TODO: remove 403 check when backend has their shit together (we get 401 from REFRESH_TOKEN_EXPIRED)
        if((status === 401 || status === 403) && !retry && data.error_key !== 'INCORRECT_CREDENTIALS') {
            if(['REFRESH_TOKEN_EXPIRED', 'REFRESH_TOKEN_DATA_INVALID'].includes(data.error_key)) {
                removeLocalStorageKeys();
                return Promise.reject();
            }

            if(isRefreshing) {
                return new Promise((resolve, reject) => apiQueue.push({ resolve, reject }))
                    .then(token => {
                        headers.Authorization = localStorage.getItem('oauth2')
                            ? `OAuth2 ${token}`
                            : `Bearer ${token}`;

                        return axios(config);
                    })
                    .catch(Promise.reject);
            }

            config.retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                let data = {};
                const oauth = localStorage.getItem('oauth2');

                data = oauth
                    ? { access_token: localStorage.getItem('access_token'), oauth }
                    : { refresh_token: localStorage.getItem('refresh_token') };

                axios.post(
                    API.auth.refresh,
                    data,
                    { headers: API.headers },
                ).then(tokenRefresh => {
                    const { access_token, user, refresh_token, public_key } = tokenRefresh.data;
                    localStorage.setItem('access_token', access_token);

                    if(oauth) {
                        localStorage.setItem('user', JSON.stringify(user));

                        axios.defaults.headers.common.Authorization = `OAuth2 ${access_token}`;
                        headers.Authorization = `OAuth2 ${access_token}`;
                    } else {
                        localStorage.setItem('refresh_token', refresh_token);
                        localStorage.setItem('public_key', public_key);
                    }

                    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
                    headers.Authorization = `Bearer ${access_token}`;

                    processQueue(null, access_token);
                    resolve(axios(config));
                }).catch(tokenErr => {
                    processQueue(tokenErr, null);
                    reject(tokenErr);
                }).then(() => isRefreshing = false);
            });
        }
    }

    return Promise.reject(err);
});

export function callApi(url, method, data, headers, responseType) {
    // console.warn('called api with:', url, method, data, responseType);
    const token = localStorage.getItem('access_token') || null;
    const oauth2 = localStorage.getItem('oauth2') || null;

    headers = headers || API.headers;

    if(token) {
        if(token) {
            headers.Authorization = oauth2 ? `OAuth2 ${token}` : `Bearer ${token}`;
        } else {
            throw new Error('No token found in localStorage!');
        }
    }
    return axios({
        url,
        headers,
        method,
        data,
        responseType,
    }).then(response => Promise.resolve(response.data));
}

const handleSuccess = (store, next, response, showSuccess = false, type, showLoading, rest) => {
    if(showLoading) {
        store.dispatch(hideLoading());
    }

    if(showSuccess) {
        const text = SuccessMessages()[response.success_key] || SuccessMessages().GENERAL_SUCCESS;

        store.dispatch({
            type: 'SHOW_SNACKBAR',
            text,
            notificationType: 'success',
        });
    }

    next({
        response,
        type,
        ...rest,
    });

    return response;
};

const handleError = (store, next, err, showError = true, type, meta) => {
    const { response, details } = (err || {});
    const { error_key, detail } = (response?.data || {});

    let errorMessage = ErrorMessages().GENERAL_ERROR;
    let errorCode = error_key;

    store.dispatch(hideLoading());

    if(!response) {
        showError = false;
        // response is not defined when the user is offline, or when an error occurs in an action or reducer.
        errorMessage = ErrorMessages().NETWORK_ERROR;
        errorCode = 'NETWORK_ERROR';
        // The error is not outputted to console because of the catch(), so we do it here manually.
        console.warn('middleware/api.js handleError() response was falsy.', err);
    } else {
        if(response?.data) {
            errorMessage = ErrorMessages()[error_key] || errorMessage;
        }

        if(details?.[0]?.code === 'invalid') {
            errorMessage = ErrorMessages().EMAIL_INVALID;
        }
    }

    if(showError) {
        store.dispatch({
            type: 'SHOW_SNACKBAR',
            text: errorMessage,
            notificationType: 'error',
        });
    }

    next({
        error: true,
        errorMessage,
        type,
        errorCode,
        meta,
    });

    return ({
        error: true,
        errorMessage,
        detail,
        errorCode,
    });
};

export default store => next => action => {
    const callAPI = action[CALL_API];
    const { type, payload, meta } = action;

    if(type === OFFLINE_STATUS_CHANGED) {
        const { online } = payload;

        if(!online) {
            store.dispatch({
                type: 'SHOW_SNACKBAR',
                text: t`USER_OFFLINE`,
                notificationType: 'offline',
                wasOffline: true,
            });
        } else if(store.getState()?.Layout?.wasOffline) {
            store.dispatch({
                type: 'SHOW_SNACKBAR',
                text: t`USER_ONLINE`,
                notificationType: 'online',
            });
        }
    }

    if(meta) { // action received from redux-offline
        const {
            meta: { showLoading = true }, // imitate our callAPI action handling
            type,
            payload,
        } = action;

        if(type === 'REQUEST') {
            if(navigator.onLine && showLoading) store.dispatch(showLoadingBar());
        } else if(meta.success) {
            handleSuccess(store, next, payload, meta.showSuccess, null, showLoading);
        } else if(meta.error) {
            const { offline, ...rest } = meta;
            meta.error && console.warn(meta.error);
            handleError(store, next, payload, true, type, rest);
        }
        // returning any value here causes infinite loop of dispatching actions
    }

    if(typeof callAPI === 'undefined') {
        return next(action);
    }

    const {
        endpoint,
        types,
        authenticated,
        method,
        body,
        showError,
        showSuccess,
        headers,
        responseType,
        showLoading = true,
        ...rest
    } = callAPI;

    const [requestType, successType, errorType] = types;

    if(showLoading) {
        store.dispatch(showLoadingBar());
    }

    store.dispatch({ type: requestType });

    return callApi(endpoint, method, body, headers, responseType)
        .then(response => handleSuccess(store, next, response, showSuccess, successType, showLoading, rest))
        .catch(err => handleError(store, next, err, showError, errorType));
};
