import { t } from 'ttag';

export const FORMDATA_SHAPE = (car_id, fuel_type) => ({
    id: null,
    car_id,
    date: '',
    fuel_type,
    volume: '',
    price: '',
    receipt_large: null,
});

export const validate = ({ receipt_large }, setErrors) => {
    const errors = {};

    const { content_base64, content_type } = (receipt_large || {});
    !content_base64 && (errors.receipt_large = t`REQUIRED`);
    (content_type !== 'image/jpeg') && (errors.receipt_large = t`MUST_BE_JPG`);

    setErrors(errors);
    return !Object.values(errors).some(v => v);
};
