import moment from 'moment';
import { t } from 'ttag';
import { Link } from 'react-router-dom';
import { fetchLocale } from 'utils.js';
import './main.scss';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector.js';

const MileageCardContent = ({ mileage }) => {
    const { since, until, period_km, total_km_stop, address_start, address_stop, remarks, reason } = mileage;
    /*
        If you wanna test how the cards look like with example-data;
        Change the above `const` to `let`, and then uncomment some/all of these (temporarily!):
    */

    // since = null;
    // until = null;
    // total_km_stop = 0;
    // period_km = 0;
    // address_start = 'Valimotie 13 A, 00888, Helsinki';
    // address_stop = 'Valimotie 13 A, 00888, Helsinki';
    /* eslint-disable max-len */
    // remarks = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis tortor viverra, sagittis purus vel, gravida sem. Suspendisse massa odio, pretium at dignissim et, porta nec dui. Maecenas id nisi ut metus molestie ultricies. Nunc aliquam vehicula ligula, vitae hendrerit eros. Aliquam viverra in massa et imperdiet. Nunc at condimentum nulla, eu bibendum magna. Donec consectetur est eget massa egestas, et ornare neque dapibus. Quisque et tincidunt diam, et accumsan risus. Donec lobortis elit neque. Etiam velit tellus, vestibulum in aliquet sit amet, blandit sed mi. Ut at diam id orci ullamcorper ultricies.';
    // reason = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis tortor viverra, sagittis purus vel, gravida sem. Suspendisse massa odio, pretium at dignissim et, porta nec dui. Maecenas id nisi ut metus molestie ultricies. Nunc aliquam vehicula ligula, vitae hendrerit eros. Aliquam viverra in massa et imperdiet. Nunc at condimentum nulla, eu bibendum magna. Donec consectetur est eget massa egestas, et ornare neque dapibus. Quisque et tincidunt diam, et accumsan risus. Donec lobortis elit neque. Etiam velit tellus, vestibulum in aliquet sit amet, blandit sed mi. Ut at diam id orci ullamcorper ultricies.';
    /* eslint-enable max-len */

    const locale = (since || until) && fetchLocale();

    let sinceDate = null;
    let sinceTime = null;
    if(since) {
        const dSince = moment(since).local();
        sinceDate = new Intl.DateTimeFormat(locale, { month: 'short', day: '2-digit' }).format(dSince);
        sinceTime = new Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit' }).format(dSince);
    }

    let untilDate = null;
    let untilTime = null;
    if(until) {
        const dUntil = moment(until).local();
        untilDate = new Intl.DateTimeFormat(locale, { month: 'short', day: '2-digit' }).format(dUntil);
        untilTime = new Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit' }).format(dUntil);
    }

    return (
        <div className='mileage-card-content'>
            <div className='mileage-card-trip'>
                <div className='mileage-card-time since'>
                    <span className='mileage-card-date'>
                        {sinceDate}
                    </span>
                    <span className='mileage-card-clock'>
                        {sinceTime}
                    </span>
                </div>
                <div className='mileage-card-time until'>
                    <span className='mileage-card-date'>
                        {untilDate}
                    </span>
                    <span className='mileage-card-clock'>
                        {untilTime}
                    </span>
                </div>
                <span className='mileage-card-period'>
                    <span className='mileage-card-indicator since' />
                    {+(+period_km).toFixed(1)}km
                    <span className='mileage-card-indicator until' />
                </span>
                <span className='mileage-card-address since'>
                    {address_start}
                </span>
                <span className='mileage-card-address until'>
                    {address_stop}
                </span>
            </div>
            <div className='mileage-card-details'>
                {!!total_km_stop && (
                    <div className='mileage-card-detail current'>
                        <span className='mileage-card-value'>
                            {+(+total_km_stop).toFixed(1)}
                        </span>
                        <span className='mileage-card-label'>
                            {t`CURRENT_KM`}
                        </span>
                    </div>
                )}
                {(remarks || reason) && (
                    <div className='mileage-card-col'>
                        {!!reason && (
                            <div className='mileage-card-detail reason'>
                                <span className='mileage-card-value'>
                                    {reason}
                                </span>
                                <span className='mileage-card-label'>
                                    {t`REASON`}
                                </span>
                            </div>
                        )}
                        {!!remarks && (
                            <div className='mileage-card-detail remarks'>
                                <span className='mileage-card-value'>
                                    {remarks}
                                </span>
                                <span className='mileage-card-label'>
                                    {t`REMARKS`}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const MileageCard = ({ mileage }) => {
    const { id, wialon_status, journey_status } = mileage;
    const user = useShallowEqualSelector(state => state.Auth.user);

    const unopenable = journey_status === 'ongoing' ? true : wialon_status === 'waiting';
    const thisUser = user.id === mileage.driver?.id;
    const userToText = thisUser ? t`YOU` : mileage.driver?.full_name;

    return unopenable ? (
        <div className='mileage-card unopenable'>
            <span className='unopenable-card-info'>
                {t`MILEAGE_ENTRY_PENDING_DATA_UPDATE`}
            </span>
            <MileageCardContent mileage={mileage} />
        </div>
    ) : (
        <>
            <Link className='mileage-card' to={{ pathname: `/mileage/${id}` }}>
                <div className={`mileage-card-driver ${thisUser ? 'is-user' : ''}`}>
                    {mileage.driver ? userToText : t`NO_DRIVER`}
                </div>
                <MileageCardContent mileage={mileage} />
            </Link>
        </>
    );
};

export default MileageCard;
