import { t } from 'ttag';
import moment from 'moment';
import { Input, Textarea, SelectInput } from 'components/common/Input/index.js';
import { validateDecimal } from 'hooks/useField.js';

export const FIELD_COMPONENTS = {
    textarea: { Component: Textarea },
    text: { Component: Input },
    date: {
        Component: Input,
        type: 'date',
    },
    datetime_local: {
        Component: Input,
        type: 'datetime-local',
    },
    time: {
        Component: Input,
        type: 'time',
    },
    number: {
        Component: Input,
        type: 'number',
    },
    select: { Component: SelectInput },
};

export const FIELD_TYPES = field => ({
    reason: { type: 'textarea', label: t`REASON` },
    organization_id: { type: 'select', label: t`ORGANIZATION` },
    since: { type: 'datetime_local', label: t`SINCE_DATE` },
    until: { type: 'datetime_local', label: t`UNTIL_DATE` },
    total_km_start: { type: 'number', label: t`TOTAL_KM_START` },
    total_km_stop: { type: 'number', label: t`TOTAL_KM_STOP` },
    period_km: { type: 'number', label: t`PERIOD_KM` },
    address_start: { type: 'text', label: t`START_POS` },
    address_stop: { type: 'text', label: t`END_POS` },
    remarks: { type: 'textarea', label: t`REMARKS` },
})[field];

export const ORGANIZATION_LIST = shared_to => [
    { label: t`NO_ORGANIZATION`, value: '' },
    ...shared_to.map(({ name, id }) => ({ label: name, value: id })),
];

const REQUIRED_FIELDS = [];

export const fieldToInput = (name, value, error, onChange, shared_to, companyID) => {
    const { type, label } = FIELD_TYPES(name);
    const { Component, ...rest } = FIELD_COMPONENTS[type];

    const props = {
        key: name,
        ...rest,
        value: value || '',
        name,
        onChange,
        label: REQUIRED_FIELDS.includes(name) ? `${label}*` : label,
        error,
    };

    if(name === 'organization_id') {
        if(!companyID) return null;

        props.options = ORGANIZATION_LIST(shared_to);
    }

    return <Component {...props} />;
};

export const validate = (values, setErrors) => {
    const errors = {};

    Object.keys(values).forEach(k => {
        const value = values[k];

        switch(k) {
            case 'reason': { break; }
            case 'since': {
                if(value && values.until && moment(value).isAfter(values.until)) {
                    errors.since = t`END_MUST_BE_LATER_THAN_START`;
                    errors.until = errors.since;
                }
                break;
            }
            case 'until': {
                if(value && values.since && moment(value).isAfter(values.until)) {
                    errors.since = t`END_MUST_BE_LATER_THAN_START`;
                    errors.until = errors.since;
                }
                break;
            }
            case 'total_km_start':
            case 'total_km_stop':
            case 'period_km': {
                (!validateDecimal({ value })) && (errors[k] = t`MAX_2_DECIMALS`);

                if(value && value <= 0) errors[k] = t`ONLY_POSITIVE_INTEGERS_ERROR`;

                const { period_km, total_km_stop, total_km_start } = values;
                if(
                    (total_km_stop !== undefined) && (total_km_start !== undefined) && (period_km !== undefined) &&
                    (parseInt(period_km, 10) > (parseInt(total_km_stop, 10) - parseInt(total_km_start, 10)))
                ) {
                    errors.total_km_start = t`TOTAL_KM_LESS_THAN_PERIOD`;
                    errors.total_km_stop = t`TOTAL_KM_LESS_THAN_PERIOD`;
                    errors.period_km = t`TOTAL_KM_LESS_THAN_PERIOD`;
                }
                break;
            }
            case 'address_start': { break; }
            case 'address_stop': { break; }
            case 'remarks': { break; }
            case 'organization_id': { break; }
            default: { break; }
        }
    });

    REQUIRED_FIELDS.forEach(field => !values[field] && (errors[field] = t`REQUIRED`));

    setErrors(errors);

    return Object.values(errors).every(v => !v);
};

export const FIELD_SORT_ORDER = [
    'reason',
    'organization_id',
    'since',
    'until',
    'total_km_start',
    'total_km_stop',
    'period_km',
    'address_start',
    'address_stop',
    'remarks',
];
