import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createStore from './store/createStore';
import createServiceWorker from './sw/createSw';
import { initializeSentry } from './sentry';
import Routes from './Routes';
import { fetchLocale, changeLanguage } from './utils';

const locale = fetchLocale().slice(0, 2);
changeLanguage(locale);

const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState);

createServiceWorker(store);
initializeSentry();

const App = () => (
    <Provider store={store}>
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    </Provider>
);

if(window.Cypress) window.store = store;

const rootElement = document.getElementById('root');
if(rootElement.hasChildNodes()) hydrate(<App />, rootElement);
else render(<App />, rootElement);

export { App };
