import { useHistory, useParams } from 'react-router-dom';
import { t } from 'ttag';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { createBooking, deleteBooking, findBooking } from '../actions';
import TitleContainer from '../../common/TitleContainer';
import './main.scss';
import { backendFileToDataURI, formatDateTime } from '../../../utils';
import tempPlaceholder from '../../SelectVehicle/placeholder-model.png';
import PopupModal from '../../common/PopupModal';

export const ConfirmBooking = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const { start, end, carId, id } = params;
    const cars = useSelector(state => state.Booking.cars);
    const bookings = useSelector(state => state.Booking.bookings);
    const [car, setCar] = useState({});
    const booking = bookings ? bookings.filter(v => v.id === parseInt(id, 10))[0] : { };
    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        if(id) dispatch(findBooking(id));
    }, []);

    useEffect(() => {
        setCar(carId ? cars.filter(v => v.id === parseInt(carId, 10))[0] : booking?.car);
    }, [booking]);

    const onClick = () => {
        const payload = {
            since: moment(start).utc().format('YYYY-MM-DD HH:mm'),
            until: moment(end).utc().format('YYYY-MM-DD HH:mm'),
            car_id: car.id,
        };
        dispatch(createBooking(payload)).then(res => {
            if(!res.error) history.push('/booking/');
        });
    };

    const onDelete = () => {
        dispatch(deleteBooking(parseInt(id, 10))).then(res => {
            if(!res.error) history.push('/booking/');
        });
    };

    return (
        <section id='confirm-booking'>
            <TitleContainer
                path={carId ? `/booking/new/${start}/${end}` : `/booking/`}
                title={t`NEW_BOOKING`}
            />
            <img
                src={car?.thumbnail_small ? backendFileToDataURI(car?.thumbnail_small) : tempPlaceholder}
                alt={t`CAR_IMAGE`}
            />
            <div className='card-content'>
                <span>{car?.car_data?.plate}</span>
                <span>
                    {t`START_TIME`}: {carId ? formatDateTime(start).full : formatDateTime(booking?.since)?.full}
                </span>
                <span>
                    {t`END_TIME`}: {carId ? formatDateTime(end).full : formatDateTime(booking?.until)?.full}
                </span>
            </div>
            {carId ?
                <button className='button button--primary' type='button' onClick={onClick}>{t`CONFIRM`}</button> : (
                    <button
                        className='button button--secondary'
                        type='button'
                        onClick={() => setPopupVisible(true)}
                    >
                        {t`CANCEL`}
                    </button>
                )}
            <PopupModal
                visible={popupVisible}
                title={t`CONFIRM_HEADER`}
                body={t`CONFIRM_BOOKING_CANCEL`}
                onCancel={() => setPopupVisible(false)}
                onConfirm={onDelete}
            />

        </section>
    );
};

export default ConfirmBooking;
