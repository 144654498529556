import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { resetLoading } from 'react-redux-loading-bar';
// Regular, non-lazy imports for offline use
import ListBookings from 'components/Booking/ListBookings/index.js';
import RouteLoading from 'components/layouts/LoadingViews/RouteLoading';
import Home from 'components/Home/index.js';
import Journey from 'components/Journey/index.js';
import Vehicle from 'components/Vehicle/index.js';
import Fuel from 'components/Fuel/index.js';
import AddOrEditFuel from 'components/Fuel/AddOrEditFuel/index.js';
import Mileage from 'components/Mileage/index.js';
import AddOrEditMileage from 'components/Mileage/AddOrEditMileage/index.js';
import Booking from 'components/Booking/index.js';
import AddBooking from 'components/Booking/AddBooking/index.js';
import ConfirmBooking from 'components/Booking/ConfirmBooking/index.js';
import SelectCarBooking from 'components/Booking/SelectCarBooking/index.js';
import 'styles/base.scss';

// Lazy imports to optimize webpack chunks
const NotFound = lazy(() => import('components/layouts/NotFound'));
const CoreLayout = lazy(() => import('components/layouts/CoreLayout'));
const Login = lazy(() => import('components/Auth/Login/index.js'));
const SetPassword = lazy(() => import('components/Auth/SetPassword/index.js'));
const Logout = lazy(() => import('components/Auth/Logout/index.js'));
const SelectVehicle = lazy(() => import('components/SelectVehicle/index.js'));
const QrReader = lazy(() => import('components/SelectVehicle/QRReader/index.js'));
const Settings = lazy(() => import('components/Settings/index.js'));
const Profile = lazy(() => import('components/Settings/Profile/index.js'));
const Language = lazy(() => import('components/Settings/Language/index.js'));

const Routes = props => {
    const dispatch = useDispatch();

    // Defined here for shorter syntax in `routes` -definition
    const requiresVehicle = true;
    const notPrivate = true;

    const routes = [
        { path: '/login', component: Login, title: 'Login', notPrivate },
        { path: '/logout', component: Logout, title: 'Logout' },
        { path: '/reset/:token', component: SetPassword, title: 'Set Password', notPrivate },
        { path: '/', component: Home, title: 'Home', requiresVehicle },
        { path: '/select', component: SelectVehicle, title: 'Select Vehicle' },
        { path: '/select/scan', component: QrReader, title: 'QrReader' },
        { path: '/journey', component: Journey, title: 'Journey', requiresVehicle },
        { path: '/vehicle', component: Vehicle, title: 'Vehicle', requiresVehicle },
        { path: '/mileage', component: Mileage, title: 'Mileage', requiresVehicle },
        { path: '/mileage/add', component: AddOrEditMileage, title: 'Add mileage', requiresVehicle },
        { path: '/mileage/:id', component: AddOrEditMileage, title: 'Edit mileage', requiresVehicle },
        { path: '/fuel', component: Fuel, title: 'Vehicle Fuel', requiresVehicle },
        { path: '/fuel/new', component: AddOrEditFuel, title: 'Add Fuel Record', requiresVehicle },
        { path: '/fuel/:id', component: AddOrEditFuel, title: 'Edit Fuel Record', requiresVehicle },
        { path: '/bookings/:id', component: ListBookings, title: 'Booking' },
        { path: '/booking', component: Booking, title: 'Booking' },
        { path: '/booking/new', component: AddBooking, title: 'Booking' },
        { path: '/booking/new/:start/:end', component: SelectCarBooking, title: 'Booking' },
        { path: '/booking/new/:start/:end/:carId', component: ConfirmBooking, title: 'Booking' },
        { path: '/booking/:id', component: ConfirmBooking, title: 'Booking' },
        { path: '/settings', component: Settings, title: 'Settings' },
        { path: '/settings/profile', component: Profile, title: 'Profile' },
        { path: '/settings/language', component: Language, title: 'Language' },
    ];

    useEffect(() => {
        props.history.listen(() => dispatch(resetLoading())); // Reset progress bar when path changes
    }, [dispatch]);

    return (
        <Suspense fallback={<RouteLoading />}>
            <Switch>
                {routes.map(({ component, path, title, notPrivate, requiresVehicle, navParent }) => (
                    <Route
                        exact
                        path={path}
                        key={title}
                        render={route => {
                            const { Auth, SelectVehicle } = props;

                            if(!notPrivate && !Auth.isAuthenticated) return <Redirect to='/login' />;
                            else if(requiresVehicle && !SelectVehicle.currentVehicle) return <Redirect to='/select' />;

                            return <CoreLayout component={component} route={route} navParent={navParent} />;
                        }}
                    />
                ))};
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};

const mapStateToProps = state => ({
    Auth: state.Auth,
    Layout: state.Layout,
    SelectVehicle: state.SelectVehicle,
});

export default withRouter(connect(mapStateToProps)(Routes));
