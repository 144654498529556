import {
    REQUEST,
    FAILURE,
    GET_JOURNEY_CUSTOMERS,
    START_JOURNEY,
    END_JOURNEY,
    INITIATE_JOURNEY_REQUEST,
    INITIATE_JOURNEY_FAILURE,
} from 'components/Journey/actions.js';

const initialState = { customers: [], disabled: {} };

function journey(state = initialState, action) {
    switch(action.type) {
        case REQUEST: { return state; }
        case INITIATE_JOURNEY_REQUEST: {
            const { key } = action;
            return { ...state, disabled: { ...state.disabled, [key]: true } };
        }
        case INITIATE_JOURNEY_FAILURE: {
            const { key } = action;
            const disabled = { ...state.disabled, [key]: false };
            return { ...state, disabled };
        }
        case FAILURE: {
            return { ...state, error: action.error };
        }
        case GET_JOURNEY_CUSTOMERS: {
            return { ...state, customers: action.response };
        }
        case START_JOURNEY: {
            const { organization_id, company_id } = action.payload;
            const { key } = action;

            const customers = state.customers.map(customer => (
                ((customer.organization_id === organization_id) && (customer.company_id === company_id))
                    ? ({ ...customer, mileage: action.payload, since_now: 0 })
                    : customer
            ));
            const disabled = { ...state.disabled, [key]: false };
            return { ...state, customers, disabled };
        }
        case END_JOURNEY: {
            const { organization_id, company_id } = action.payload;

            const customers = state.customers.map(customer => (
                ((customer.organization_id === organization_id) && (customer.company_id === company_id))
                    ? ({ ...customer, mileage: null, since_now: null })
                    : customer
            ));

            return { ...state, customers };
        }
        default: { return state; }
    }
}

export default journey;
