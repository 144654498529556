import { combineReducers } from 'redux';

import { loadingBarReducer } from 'react-redux-loading-bar';
import Layout from 'components/layouts/reducers';
import ServiceWorker from 'sw/reducers';

import Auth from 'components/Auth/reducers';
import SelectVehicle from 'components/SelectVehicle/reducers';
import Journey from 'components/Journey/reducers.js';
import Fuel from 'components/Fuel/reducers.js';
import Mileage from 'components/Mileage/reducers';
import Booking from 'components/Booking/reducers';
import Settings from 'components/Settings/reducers';
import Home from 'components/Home/reducers.js';

const appReducer = combineReducers({
    ServiceWorker,
    Layout,
    Auth,
    Home,
    SelectVehicle,
    Journey,
    Fuel,
    Mileage,
    Settings,
    Booking,
    loadingBar: loadingBarReducer,
});

const makeRootReducer = (state, action) => (
    action.type === 'LOGOUT'
        ? appReducer(undefined, action)
        : appReducer(state, action)
);

export default makeRootReducer;
