import jwt from 'jsonwebtoken';

import { getParsedLSItemOr } from 'utils.js';

import {
    REQUEST,
    FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
} from './actions';

const initialState = {
    isAuthenticated: !!localStorage.getItem('access_token'),
    user: getParsedLSItemOr('user', null),
    organizationID: getParsedLSItemOr('organization_id', null, true),
    companyID: getParsedLSItemOr('company_id', null, true),
    error: null,
};

export default function auth(state = initialState, action) {
    switch(action.type) {
        case REQUEST: { return state; }
        case FAILURE: {
            return {
                ...state,
                error: action.errorMessage,
            };
        }
        case LOGIN_SUCCESS: {
            const {
                organization_id,
                access_token,
                is_driver,
                public_key,
                refresh_token,
                company_id,
            } = action.response;

            const decoded = jwt.verify(access_token, public_key);

            if(is_driver) {
                // { || null }, because { undefined } is not a valid JSON value
                localStorage.setItem('user', JSON.stringify(decoded.user));
                localStorage.setItem('access_token', access_token || null);
                localStorage.setItem('refresh_token', refresh_token || null);
                localStorage.setItem('public_key', public_key || null);
                localStorage.setItem('is_driver', is_driver || null);
                localStorage.setItem('company_id', company_id || null);
                localStorage.setItem('organization_id', organization_id || null);
            }

            return {
                ...state,
                isAuthenticated: is_driver,
                user: decoded.user,
                error: null,
                isDriver: is_driver,
                organizationID: organization_id || null,
                companyID: company_id || null,
            };
        }
        case LOGOUT: {
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('public_key');
            localStorage.removeItem('is_driver');
            localStorage.removeItem('company_id');
            localStorage.removeItem('organization_id');
            localStorage.removeItem('currentVehicle');

            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: action.data,
            };
        }
        default: { return state; }
    }
}
