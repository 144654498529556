import { applyMiddleware, compose, createStore } from 'redux';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import thunk from 'redux-thunk';
import * as localforage from 'localforage';
import api, { callApi } from 'middleware/api';
import async from 'middleware/async';
import makeRootReducer from './reducers';

const effect = effect => callApi(effect.endpoint, effect.method, effect.body);

const discard = error => {
    const { request, response } = error;
    if(!request) throw error; // There was an error creating the request
    if(!response) return false; // Retry if there was no response (backend down)
    return true; // No retry normally
};

const persistOptions = { storage: localforage, blacklist: ['Layout', 'loadingBar', 'Auth'] };

const customConfig = {
    ...offlineConfig,
    effect,
    discard,
    persistOptions,
    returnPromises: true, // Experimental. https://redux-offline.github.io/redux-offline/docs/api/config#returnpromises
};
const { middleware, enhanceReducer, enhanceStore } = createOffline(customConfig);

const middlewares = [thunk, middleware, api, async];

export default (initialState = {}) => {
    const store = createStore(
        enhanceReducer(makeRootReducer),
        initialState,
        compose(
            enhanceStore,
            applyMiddleware(...middlewares),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
        ),
    );

    store.asyncReducers = {};

    return store;
};
