import React, { useState, useEffect } from 'react';
import { t } from 'ttag';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { ReactComponent as IconArrow } from 'static/Common/icon-arrow-up.svg';
import { ReactComponent as IconEye } from 'static/Common/eye.svg';
import { ReactComponent as IconLocation } from 'static/Common/icon-location.svg';
import FieldError from 'components/common/FieldError/index.js';
import { fetchLocale } from 'utils.js';
import './main.scss';

export const Input = ({ label, type = 'text', error, className = '', children, ...rest }) => {
    if(type === 'number') {
        rest.step = rest.step ?? 0.01;
        rest.onWheel = rest.onWheel ?? (e => e.target.blur());
    }

    return (
        <label className={`form-label ${className}`}>
            {label}
            <div className={`form-field ${type} ${error ? 'invalid' : ''} ${children ? 'with-side-content' : ''}`}>
                <input type={type} {...rest} />
                {children}
            </div>
            <FieldError error={error} />
        </label>
    );
};

export const InputWithoutLabelElement = ({ label, type = 'text', error, className = '', children, ...rest }) => {
    if(type === 'number') {
        rest.step = rest.step ?? 0.01;
        rest.onWheel = rest.onWheel ?? (e => e.target.blur());
    }

    return (
        <span className={`form-label ${className}`}>
            {label}
            <div className={`form-field ${type} ${error ? 'invalid' : ''} ${children ? 'with-side-content' : ''}`}>
                <input type={type} {...rest} />
                {children}
            </div>
            <FieldError error={error} />
        </span>
    );
};

export const PasswordInput = ({ ...props }) => {
    const [hidden, setHidden] = useState(true);

    return (
        <InputWithoutLabelElement {...props} className='password-field' type={hidden ? 'password' : 'text'}>
            <button type='button' className='password-eye' onClick={() => setHidden(!hidden)}>
                <div className='password-eye-wrap'>
                    <span className={`${hidden ? '' : 'password-eye-liner'}`} />
                    <IconEye title={`${hidden ? t`SHOW_PASSWORD` : t`HIDE_PASSWORD`}`} />
                </div>
            </button>
        </InputWithoutLabelElement>
    );
};

export const CustomToggle = ({ checked, name, value, onChange, label, disabled = false }) => (
    <label className={`custom-toggle ${checked ? 'on' : ''}`}>
        <span className='custom-toggle-label'>
            {label}
        </span>
        <input type='checkbox' name={name} onChange={onChange} checked={checked} value={value} disabled={disabled} />
        <span className='switch'>
            <span className='switch-btn' />
        </span>
    </label>
);

export const SelectInput = ({ label, options, value, error, className = '', hideArrow = false, ...rest }) => (
    <label className={`form-label select-input ${rest.disabled ? 'disabled' : ''} ${className}`}>
        {label}
        <div>
            <select value={value} {...rest}>
                {options.map((option, i) => (
                    <option key={i} value={option.value} disabled={!!option.disabled}>
                        {option.label}
                    </option>
                ))}
            </select>
            {!hideArrow && <IconArrow />}
        </div>
    </label>
);

export const Textarea = ({ label, error, className = '', ...rest }) => (
    <label className={`form-label ${className}`}>
        {label}
        <div className='form-field textarea'>
            <textarea className={`${error ? 'invalid' : ''}`} {...rest} />
        </div>
        <FieldError error={error} />
    </label>
);

export const CustomInput = ({ label, error, className = '', children, ...rest }) => (
    <label className={`form-label ${className}`} {...rest}>
        {label}
        <div className={`form-field custom-input ${error ? 'invalid' : ''}`}>
            {children}
        </div>
        <FieldError error={error} />
    </label>
);

export const GooglePlacesAutocompleteInput = ({ value, onAddressClick, location, ...rest }) => {
    const [showPredictions, setShowPredictions] = useState(false);

    const { getQueryPredictions, queryPredictions } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 128,
        options: { types: ['address'] },
        language: fetchLocale().slice(0, 2),
    });

    useEffect(() => {
        getQueryPredictions({
            input: value,
            location: location ? new window.google.maps.LatLng(location) : undefined,
            radius: location ? 100000 : undefined,
        });
    }, [value]);

    const handleBlur = e => !e.currentTarget.contains(e.relatedTarget) && setShowPredictions(false);

    const handleAddressClick = description => {
        setShowPredictions(false);
        onAddressClick(description);
    };

    return (
        <div className={`autocomplete-container ${showPredictions ? 'open' : ''}`} onBlur={handleBlur}>
            <Input value={value} {...rest} onFocus={() => setShowPredictions(true)}>
                <IconLocation />
            </Input>
            <ul className='autocomplete-list'>
                {queryPredictions.map(({ description }, i) => (
                    <li key={i}>
                        <button type='button' onClick={() => handleAddressClick(description)}>
                            {description}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
