import { changeLanguage } from 'utils';

export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const HIDE_NOTIFICATION_MODAL = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const UPDATE_SW = 'UPDATE_SW';

export function hideSnackbar() {
    return { type: HIDE_SNACKBAR };
}

export function showSnackbar(text, notificationType) {
    return {
        type: SHOW_SNACKBAR,
        text,
        notificationType,
    };
}

export function hideNotificationModal() {
    return { type: HIDE_NOTIFICATION_MODAL };
}

export function showNotificationModal(title, body, options) {
    return {
        type: SHOW_NOTIFICATION_MODAL,
        title,
        body,
        options,
    };
}

export function changeLocale(langCode) {
    changeLanguage(langCode);

    return {
        type: CHANGE_LOCALE,
        lang: langCode,
    };
}

export function update() {
    return { type: UPDATE_SW };
}
