import { useRef, useEffect } from 'react';

const useRefMounted = () => {
    const refMounted = useRef(null);
    useEffect(() => {
        refMounted.current = true;
        return () => refMounted.current = false;
    });
    return refMounted;
};

export default useRefMounted;
