import {
    REMOVE_CURRENT_VEHICLE,
} from 'components/SelectVehicle/actions.js';

import {
    REQUEST,
    FAILURE,
    ADD_MILEAGE,
    UPDATE_MILEAGE,
    DELETE_MILEAGE,
    DELETE_MILEAGE_REQUEST,
    GET_MILEAGE_LIST,
    GET_MILEAGE_DETAILS,
    GET_MILEAGE_FIELDS,
    MILEAGE_UPDATE_REQUEST,
    MILEAGE_ADD_REQUEST,
} from './actions.js';

export const DELETED = Symbol('DELETED'); // TODO: This could be defined in a more global place

// TODO: I think this should be changed to not compare `since` and `until` together, but rather just `since`
const mileageSort = (a, b) => {
    // If both have `since`, compare those
    const aSince = a.since;
    const bSince = b.since;
    if(aSince && bSince) return bSince.localeCompare(aSince, undefined, { numeric: true });

    // If both have only `until`, compare those
    const aUntil = a.until;
    const bUntil = b.until;
    if(aUntil && bUntil) return bUntil.localeCompare(aUntil, undefined, { numeric: true });

    // If `a` has `since` and `b` has only `until`, compare those
    if(aSince && bUntil) return bUntil.localeCompare(aSince, undefined, { numeric: true });

    // If `b` has `since` and `a` has only `until`, compare those
    if(bSince && aUntil) return bSince.localeCompare(aUntil, undefined, { numeric: true });

    // If `a` has either `since` or `until`, but `b` has neither, return -1
    if((aSince || aUntil) && !bSince && !bUntil) return -1;

    // If `b` has either `since` or `until` but `a` has neither, return 1
    if((bSince || bUntil) && !aSince && !aUntil) return 1;

    // If neither have `since` and `until`, do descending order of `id`. Newest to oldest
    const aId = a.id;
    const bId = b.id;
    return ((bId - aId) > 0) ? 1 : -1;
};

const initialState = {
    mileages: [],
    fields: {},
    mileageDetails: {},
    disabled: false,
};

function mileage(state = initialState, action) {
    switch(action.type) {
        case REQUEST: { return state; }
        case FAILURE: {
            return { ...state, error: action.error };
        }
        case MILEAGE_UPDATE_REQUEST: {
            return { ...state, disabled: true };
        }
        case MILEAGE_ADD_REQUEST: {
            return { ...state, disabled: true };
        }
        case ADD_MILEAGE: {
            const mileages = [action.payload, ...state.mileages].sort(mileageSort);

            return { ...state, mileages, disabled: false };
        }
        case UPDATE_MILEAGE: {
            const updatedMileages = [...state.mileages];
            const index = updatedMileages.findIndex(mileage => mileage.id === action.payload.id);

            updatedMileages[index] = action.payload;

            return {
                ...state,
                mileages: updatedMileages.sort(mileageSort),
                mileageDetails: action.payload,
                disabled: false,
            };
        }
        case DELETE_MILEAGE_REQUEST: {
            const id = parseInt(action.meta.id, 10);
            const mileages = [...state.mileages];

            mileages.find(record => record.id === id)[DELETED] = true;

            return { ...state, mileages };
        }
        case DELETE_MILEAGE: {
            const mileages = [...state.mileages].filter(record => !record[DELETED]);
            return { ...state, mileages };
        }
        case GET_MILEAGE_LIST: {
            const mileages = action.response.sort(mileageSort);

            return { ...state, mileages };
        }
        case GET_MILEAGE_DETAILS: {
            return { ...state, mileageDetails: action.response };
        }
        case REMOVE_CURRENT_VEHICLE: {
            return { ...state, mileages: [] };
        }
        case GET_MILEAGE_FIELDS: {
            const fields = Object.fromEntries(
                Object.entries(action.response).map(([fieldName, v]) => [
                    fieldName,
                    v.visible_driver,
                ]).filter(([field]) => action.response[field].visible_driver),
            );
            fields.organization_id = true;

            return { ...state, fields };
        }
        default: { return state; }
    }
}

export default mileage;
