import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_VEHICLES = 'GET_VEHICLES';
export const SCAN_VEHICLE = 'SCAN_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const GET_SINGLE_VEHICLE = 'GET_SINGLE_VEHICLE';
export const REMOVE_CURRENT_VEHICLE = 'REMOVE_CURRENT_VEHICLE';
export const GET_DROPDOWNS = 'GET_DROPDOWNS';

export function getVehicles() {
    return {
        [CALL_API]: {
            endpoint: API.cars.history,
            method: 'GET',
            types: [REQUEST, GET_VEHICLES, FAILURE],
        },
    };
}

export function scanVehicle(body) {
    return {
        [CALL_API]: {
            endpoint: API.cars.qr,
            method: 'POST',
            types: [REQUEST, SCAN_VEHICLE, FAILURE],
            body,
            showSuccess: true,
            showError: false,
        },
    };
}

export function getSingleVehicle(id) {
    return {
        type: REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.cars.single.replace(':id', id), method: 'GET' },
                commit: { type: GET_SINGLE_VEHICLE, meta: { id } },
                rollback: { type: FAILURE },
            },
        },
    };
}

export function updateVehicle(id, body) {
    return {
        type: REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.cars.update.replace(':id', id), method: 'PUT', body },
                commit: { type: UPDATE_VEHICLE, meta: { id, showSuccess: true } },
                rollback: { type: FAILURE },
            },
        },
    };
}

export function selectVehicle(currentVehicle) {
    return {
        type: GET_SINGLE_VEHICLE,
        payload: { ...currentVehicle, partialVehicleData: true },
    };
}

export function removeCurrentVehicle() {
    return { type: REMOVE_CURRENT_VEHICLE };
}

export function getDropdownData() {
    return {
        [CALL_API]: {
            endpoint: API.cars.dropdowns,
            method: 'GET',
            types: [REQUEST, GET_DROPDOWNS, FAILURE],
        },
    };
}
