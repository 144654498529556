import { useState } from 'react';
import { Link } from 'react-router-dom';
import TitleContainer from 'components/common/TitleContainer/index.js';
import './main.scss';

const INITIAL_BOX_SHADOW_SPREAD_RADIUS = -14;

const ListViewContainer = ({
    className = '',
    title,
    listTitle,
    noItemsText,
    button = {},
    history,
    ...rest
}) => {
    const [boxShadowSpreadRadius, setBoxShadowSpreadRadius] = useState(INITIAL_BOX_SHADOW_SPREAD_RADIUS);

    const onScroll = e => setBoxShadowSpreadRadius(INITIAL_BOX_SHADOW_SPREAD_RADIUS + Math.min(e.target.scrollTop, 7));
    const boxShadow = `0 9px 5px ${boxShadowSpreadRadius}px rgba(0, 0, 0, .3)`;
    const { link, icon, text } = button;

    return (
        <section className={`list-view ${className}`} {...rest} onScroll={onScroll}>
            <div className='header-wrap'>
                <TitleContainer title={title} />
                {Object.keys(button).length !== 0 ? (
                    <Link to={link} className='button button--primary'>
                        {icon}
                        <span>{text}</span>
                    </Link>
                ) : <></>}
                <span className='title' style={{ boxShadow }}>{listTitle}</span>
            </div>
            <section>
                {history.length ? (
                    <>
                        <ol>{history}</ol>
                    </>
                ) : <span className='no-items'>{noItemsText}</span>}
            </section>
        </section>
    );
};

export default ListViewContainer;
