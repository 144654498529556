import './main.scss';
import { t } from 'ttag';

const PopupModal = ({ visible, body, onCancel, onConfirm, title, confirmLabel = t`CONFIRM` }) => (
    <div className={`popup ${visible ? 'visible' : ''}`}>
        <button type='button' className='modal-backdrop' onClick={onCancel} aria-label={t`CLOSE`} />
        <div className='popup-content'>
            <span className='popup-title'>{title}</span>
            <p className='popup-text'>{body}</p>
            <button className='button--primary' onClick={onConfirm} type='button'>{confirmLabel}</button>
            <button className='button--secondary' onClick={onCancel} type='button'>{t`CANCEL`}</button>
        </div>
    </div>
);

export default PopupModal;
