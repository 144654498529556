import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';

export function login(credentials) {
    return {
        [CALL_API]: {
            endpoint: API.auth.token,
            method: 'POST',
            types: [REQUEST, LOGIN_SUCCESS, FAILURE],
            showSuccess: false,
            body: {
                username: credentials.username,
                password: credentials.password,
            },
        },
    };
}

export function logout() {
    return {
        type: 'LOGOUT',
    };
}

export function setNewPassword(token, new_password) {
    return {
        [CALL_API]: {
            endpoint: API.user.new_password.replace(':token', token),
            method: 'PUT',
            types: [REQUEST, SET_NEW_PASSWORD, FAILURE],
            body: { new_password },
            showSuccess: false,
        },
    };
}
