import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import TitleContainer from 'components/common/TitleContainer/index.js';
import { ReactComponent as IconClose } from 'static/Common/icon-close.svg';
import { FIELDSETS, formatCarObject, validate, I18N } from 'components/Vehicle/utils.js';
import Fieldset from 'components/Vehicle/Fieldset/index.js';
import { toBase64 } from 'utils.js';
import { getSingleVehicle, updateVehicle, getDropdownData } from '../SelectVehicle/actions.js';
import './main.scss';

const Vehicle = () => {
    const refForm = useRef(null);
    const dispatch = useDispatch();
    const currentVehicle = useSelector(state => state.SelectVehicle.currentVehicle);
    const partialVehicleData = useSelector(state => state.SelectVehicle.partialVehicleData);

    const [formTouched, setFormTouched] = useState(false);
    const [values, setValues] = useState(formatCarObject(currentVehicle));
    const [disabled, setDisabled] = useState(partialVehicleData);
    const [errors, setErrors] = useState({});
    const [lists, setLists] = useState({});

    const vehicle = formatCarObject(currentVehicle);
    const i18n = I18N();

    useEffect(() => {
        partialVehicleData && dispatch(getSingleVehicle(currentVehicle.id));

        dispatch(getDropdownData()).then(res => {
            if(!res || res.error) return console.error('Error getting dropdown data for vehicle view');

            const translated = Object.fromEntries(
                Object.entries(res).map(([key, values]) => ((key === 'fuel_categories')
                    ? [key, values]
                    : [
                        key,
                        values
                            .map(value => ({ value, label: i18n[value] || value }))
                            .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true })),
                    ]
                )),
            );

            const { fuel_type } = currentVehicle.car_data;
            translated.fuel_types = res.fuel_categories
                .find(category => category.fuel_types.includes(fuel_type))
                ?.fuel_types
                .map(value => ({ label: i18n[value] || value, value })) || [];

            setLists(translated);
        });
    }, []);

    useEffect(() => {
        setValues(formatCarObject(currentVehicle));
        !partialVehicleData && setDisabled(false);
    }, [currentVehicle]);

    const onCancel = () => {
        refForm.current.reset();
        setFormTouched(false);
        setValues(formatCarObject(currentVehicle));
        setErrors({});
    };

    const onSubmit = async e => {
        e.preventDefault();

        if(!validate(values, vehicle, setErrors)) return;

        const formattedValues = Object.fromEntries(
            Object.entries(values)
                .filter(([key]) => vehicle[key] !== values[key]) // Filter out values that haven't changed from origs
                .map(([key, val]) => [key, val === '' ? null : val]), // Convert `''` values to `null` instead
        );

        const { nedc_emissions_file, wltp_emissions_file } = formattedValues;

        if(nedc_emissions_file) {
            let content_base64 = await toBase64(nedc_emissions_file);
            content_base64 = content_base64.slice(content_base64.indexOf(','));

            formattedValues.nedc_emissions_file = {
                content_base64,
                content_type: nedc_emissions_file.type,
                name: nedc_emissions_file.name,
            };
        }

        if(wltp_emissions_file) {
            let content_base64 = await toBase64(wltp_emissions_file);
            content_base64 = content_base64.slice(content_base64.indexOf(','));

            formattedValues.wltp_emissions_file = {
                content_base64,
                content_type: wltp_emissions_file.type,
                name: wltp_emissions_file.name,
            };
        }

        dispatch(updateVehicle(vehicle.car_data_id, formattedValues)).then(res => {
            if(!res || res.error) return console.error('Error updating vehicle data,', res, res?.error);
        });
    };

    const handleChange = (name, value) => {
        !formTouched && setFormTouched(true);
        setValues({ ...values, [name]: value });
    };

    return (
        <section id='vehicle' className={formTouched ? 'touched' : undefined}>
            <TitleContainer title={t`VEHICLE_DATA_CATEGORIES`} />
            <header className='vehicle-header'>
                <h1>{vehicle.plate}</h1>
                <p>{t`VEHICLE_DATA_HEADER_BODY`}</p>
            </header>
            <form ref={refForm} onSubmit={onSubmit}>
                {FIELDSETS().map(({ icon, label, fields }, i) => (
                    <Fieldset
                        key={i}
                        icon={icon}
                        label={label}
                        fields={fields}
                        values={values}
                        vehicle={vehicle}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                        lists={lists}
                        i18n={i18n}
                    />
                ))}
                <div className='vehicle-form-actions'>
                    <button type='button' onClick={onCancel} title={t`CANCEL`}>
                        <IconClose />
                    </button>
                    <input type='submit' value={t`SAVE`} className='button button--primary' />
                </div>
            </form>
        </section>
    );
};

export default Vehicle;
