import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { ReactComponent as IconType } from 'static/Common/icon-type.svg';
import HistoryViewContainer from 'components/common/HistoryViewContainer/index.js';
import { addDividerIfNeeded } from 'components/common/ListViewContainer/utils.js';
import MileageCard from 'components/Mileage/MileageCard/index.js';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector.js';
import { DELETED } from 'components/Mileage/reducers.js';
import { getMileageList } from './actions.js';
import './main.scss';

const Mileage = () => {
    const dispatch = useDispatch();
    const mileages = useShallowEqualSelector(state => state.Mileage.mileages);
    const currentVehicle = useShallowEqualSelector(state => state.SelectVehicle.currentVehicle);

    useEffect(() => dispatch(getMileageList(currentVehicle.id)), []);

    const mileageButton = {
        icon: <IconType />,
        text: t`ADD_MILEAGE_BUTTON`,
        link: '/mileage/add',
    };

    return (
        <HistoryViewContainer
            id='mileage'
            title={t`MILEAGE_TITLE`}
            button={mileageButton}
            history={mileages.map((mileage, i) => (
                <React.Fragment key={i}>
                    <li className={mileage[DELETED] ? 'deleted' : ''}>
                        <MileageCard key={i} mileage={mileage} />
                    </li>
                    {addDividerIfNeeded(mileage, mileages[i + 1], 'since')}
                </React.Fragment>
            ))}
        />
    );
};

export default Mileage;
