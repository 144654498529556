import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import moment from 'moment';
import { Input, Textarea, GooglePlacesAutocompleteInput } from 'components/common/Input/index.js';
import { startJourney, endJourney, getAddressFromCoords } from 'components/Journey/actions.js';
import { getCoords, validate } from 'components/Journey/utils.js';
import { fetchLocale } from 'utils.js';
import './main.scss';

let hTimeout = null;
const BLANK = { mileage: '', location: '', remarks: '', reason: '', stops: '' };

const Modal = ({ close, customer, onToggle }) => {
    const dispatch = useDispatch();
    const car_id = useSelector(state => state.SelectVehicle.currentVehicle.id);
    const wialon = useSelector(state => state.SelectVehicle.currentVehicle.wialon);
    const [formData, setFormData] = useState(BLANK);
    const [coords, setCoords] = useState(null);
    const [defaultAddress, setDefaultAddress] = useState('');
    const [ongoing, setOngoing] = useState(!!customer?.mileage);
    const [errors, setErrors] = useState(BLANK);

    const isOpen = !!customer;
    const { company_id, organization_id } = customer || {};
    const { mileage, location, remarks, reason, stops } = formData;

    useEffect(() => () => clearTimeout(hTimeout), []);
    useEffect(() => !location && setFormData({ ...formData, location: defaultAddress }), [defaultAddress]);

    useEffect(() => {
        customer
            ? setOngoing(!!customer.mileage)
            : hTimeout = setTimeout(() => setOngoing(null), 200);
    }, [customer]);

    useEffect(() => {
        // Only gets default location the first time the "Start" modal is opened, to reduce API calls and not ask
        // location permissions unnecessarily.
        if(isOpen && !wialon && !ongoing) {
            if(!defaultAddress) {
                getCoords(coords => {
                    setCoords(coords);

                    dispatch(getAddressFromCoords(coords, fetchLocale().slice(0, 2))).then(res => {
                        if(!res || res.error) return console.error(res);

                        setDefaultAddress(res.location || '');
                    });
                });
            } else if(!location) setFormData(formData => ({ ...formData, location: defaultAddress }));
        }
    }, [isOpen]);

    const handleSubmit = e => {
        e.preventDefault();

        if(!validate(ongoing, wialon, formData, setErrors)) return;

        const payload = {
            car_id,
            ...(company_id && { company_id }),
            ...(organization_id && { organization_id }),
        };

        if(ongoing) { // Ending journey
            if(!wialon) {
                payload.total_km_stop = mileage;
                payload.address_stop = location;
            } else payload.wialon = true;

            payload.id = customer.mileage?.id;
            payload.until = moment().toISOString();
            payload.remarks = remarks || undefined;
            payload.reason = reason;
            payload.address_break = stops || undefined;

            dispatch(endJourney(payload));
            onToggle(organization_id, company_id, false);
        } else { // Starting journey
            payload.since = moment().toISOString();
            payload.total_km_start = mileage;
            payload.address_start = location;

            dispatch(startJourney(payload));
            onToggle(organization_id, company_id, true);
        }

        handleClose();
    };

    const handleClose = () => {
        hTimeout = setTimeout(() => {
            setErrors(BLANK);
            setFormData(BLANK);
        }, 200);

        close();
    };

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    return (
        <div className={`journey-modal-container ${isOpen ? 'open' : ''}`}>
            <button type='button' className='modal-backdrop' onClick={handleClose} aria-label={t`CLOSE`} />
            <form className='modal' onSubmit={handleSubmit} onReset={handleClose}>
                <span className='title'>
                    {ongoing ? t`JOURNEY_END_MODAL_TITLE` : t`JOURNEY_START_MODAL_TITLE`}
                </span>
                {!wialon && (
                    <>
                        <Input
                            label={t`MILEAGE`}
                            name='mileage'
                            value={mileage}
                            onChange={onChange}
                            error={errors.mileage}
                        />
                        <GooglePlacesAutocompleteInput
                            label={t`LOCATION`}
                            name='location'
                            value={location}
                            onChange={onChange}
                            onAddressClick={location => setFormData({ ...formData, location })}
                            error={errors.location}
                            location={coords}
                        />
                    </>
                )}
                {!!ongoing && (
                    <>
                        <Textarea
                            label={t`STOPS_ON_ROUTE`}
                            name='stops'
                            value={stops}
                            onChange={onChange}
                            error={errors.stops}
                        />
                        <Textarea
                            label={t`REASON`}
                            name='reason'
                            value={reason}
                            onChange={onChange}
                            error={errors.reason}
                        />
                        <Textarea
                            label={t`REMARKS`}
                            name='remarks'
                            value={remarks}
                            onChange={onChange}
                            error={errors.remarks}
                        />
                    </>
                )}
                <input type='submit' value={t`SAVE`} className='button button--primary' />
                <input type='reset' value={t`CANCEL`} className='button button--secondary' />
            </form>
        </div>
    );
};

export default Modal;
