import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ReactComponent as IconArrow } from 'static/Common/icon-arrow-left.svg';

import './main.scss';

const TitleContainer = ({ path = '/', title }) => (
    <header id='title-container'>
        <Link to={path}>
            <IconArrow />
        </Link>
        <h1 title={title}>{title}</h1>
    </header>
);

export default withRouter(TitleContainer);
