import { useState } from 'react';
import { t } from 'ttag';

export const validateDecimal = ({ value, setError }) => {
    const handleError = setError || (() => {});
    const decimalPart = `${value}`.split('.')[1];

    if(decimalPart !== undefined) {
        return decimalPart.length <= 2 ? true : handleError(t`MAX_2_DECIMALS`);
    } else {
        return true;
    }
};

const useField = (label, name, type = 'text', ...rest) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState('');

    const onChange = e => setValue(e.target.value);

    const validate = () => {
        const msg = getValidationMsg(name, value);
        setError(msg);
        return !msg; // return true if validation succeeded
    };

    return {
        value,
        inputProps: {
            label,
            name,
            type,
            value,
            error,
            onChange,
            ...rest,
        },
        validate,
        setValue,
        setError,
    };
};

export default useField;

const validationMsg = () => ({
    username: t`EMPTY_USERNAME_ERROR`,
    pw: t`EMPTY_PASSWORD_ERROR`,
    required: t`FIELD_REQUIRED_ERROR`,
    tooBig: t`NUMBER_TOO_BIG_ERROR`,
    tooSmall: t`ONLY_POSITIVE_INTEGERS_ERROR`,
    invalidDate: t`INVALID_DATE`,
    invalidCharacters: t`INVALID_CHARACTERS_ERROR`, // only letters, numbers and @/./+/-/_ allowed
});

const allowedCharacters = /^[a-zA-Z0-9@.+_-]+$/;

const getValidationMsg = (name, value) => {
    switch(name) {
        case 'username': {
            if(!value.length) return validationMsg().username;
            else if(!allowedCharacters.test(value)) return validationMsg().invalidCharacters;
            break;
        }
        case 'email': {
            if(!value.length) return validationMsg().required;
            else if(!allowedCharacters.test(value)) return validationMsg().invalidCharacters;
            break;
        }
        case 'password': {
            return !value.length && validationMsg().pw;
        }
        case 'since':
        case 'until': {
            return !value.length && validationMsg().required;
        }
        case 'period_km':
        case 'total_km_start':
        case 'total_km_stop':
        case 'total_km': {
            if(parseInt(value, 10) > 2147483647) return validationMsg().tooBig;
            if(parseInt(value, 10) < 0) return validationMsg().tooSmall;
            break;
        }
        case 'volume':
        case 'price': {
            if(!value) return validationMsg().required;
            if(value < 0) return validationMsg().tooSmall;
            break;
        }
        case 'date': {
            if(!value) return validationMsg().required;

            const [full, year] = (value.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})$/) || []);
            if(!full) return validationMsg().invalidDate;
            if(year > 9999) return validationMsg().invalidDate;
            break;
        }
        default: {
            break;
        }
    }
};
