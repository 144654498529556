import { getParsedLSItemOr } from 'utils.js';
import { SET_CAR_IMAGE } from 'components/Home/actions.js';
import {
    REQUEST,
    FAILURE,
    GET_VEHICLES,
    SCAN_VEHICLE,
    UPDATE_VEHICLE,
    GET_SINGLE_VEHICLE,
    REMOVE_CURRENT_VEHICLE,
} from './actions.js';

const initialState = {
    vehicles: [],
    currentVehicle: getParsedLSItemOr('currentVehicle'),
    partialVehicleData: true,
};

function selectVehicle(state = initialState, action) {
    switch(action.type) {
        case REQUEST: { return state; }
        case FAILURE: {
            return { ...state, error: action.error };
        }
        case GET_VEHICLES: {
            return { ...state, vehicles: action.response };
        }
        case SCAN_VEHICLE: {
            const currentVehicle = action.response;
            localStorage.setItem('currentVehicle', JSON.stringify(currentVehicle));

            return { ...state, currentVehicle, partialVehicleData: true };
        }
        case UPDATE_VEHICLE: {
            const currentVehicle = {
                ...state.currentVehicle,
                car_data: { ...state.currentVehicle.car_data, ...action.payload },
            };

            localStorage.setItem('currentVehicle', JSON.stringify(currentVehicle));

            return { ...state, currentVehicle };
        }
        case GET_SINGLE_VEHICLE: {
            const currentVehicle = action.payload;
            localStorage.setItem('currentVehicle', JSON.stringify(currentVehicle));

            return {
                ...state,
                currentVehicle,
                partialVehicleData: !!currentVehicle.partialVehicleData,
            };
        }
        case REMOVE_CURRENT_VEHICLE: {
            localStorage.removeItem('currentVehicle');

            return { ...state, currentVehicle: undefined };
        }
        case SET_CAR_IMAGE: {
            const { thumbnail_large } = action.payload;
            let currentVehicle = localStorage.getItem('currentVehicle');

            try {
                currentVehicle = { ...JSON.parse(currentVehicle), thumbnail_large };
            } catch(e) {
                console.warn('Error parsing localStorage. Clearing localStorage.', e, localStorage);
                localStorage.clear();
            }

            localStorage.setItem('currentVehicle', JSON.stringify(currentVehicle));

            return { ...state, currentVehicle };
        }
        default: { return state; }
    }
}

export default selectVehicle;
