import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { t } from 'ttag';
import { connect } from 'react-redux';

import { ReactComponent as IconSettings } from 'static/Common/settings.svg';
import { ReactComponent as Logo } from 'static/Common/logo.svg';

import { removeCurrentVehicle } from 'components/SelectVehicle/actions.js';

import './main.scss';

const Header = ({ plate, removeCurrentVehicle }) => {

    const changeVehicle = () => {
        removeCurrentVehicle();
    };

    return (
        <header id='global-header'>
            <Link to='/'>
                <Logo />
            </Link>
            {plate && (
                <button type='button' onClick={changeVehicle} className='change-vehicle'>
                    <span className='plate'>{plate}</span>
                    <span>{t`CHANGE_VEHICLE_LINK`}</span>
                </button>
            )}
            <Link to='/settings'>
                <IconSettings />
            </Link>
        </header>
    );
};

export default withRouter(connect(null, { removeCurrentVehicle })(Header));
