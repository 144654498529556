import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SHOW_ERROR = 'SHOW_ERROR';

export function getProfile() {
    return {
        [CALL_API]: {
            endpoint: API.profile,
            method: 'GET',
            types: [REQUEST, GET_PROFILE, FAILURE],
        },
    };
}

export function updateProfile(body) {
    return {
        type: REQUEST,
        meta: {
            offline: {
                effect: { endpoint: API.profile, method: 'PUT', body },
                commit: { type: UPDATE_PROFILE, meta: { showSuccess: true } },
                rollback: { type: SHOW_ERROR },
            },
        },
    };
}
