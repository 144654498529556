import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/de';
import { addLocale, t, useLocale as doUseLocale } from 'ttag';
import { BACKEND_DATETIME_FORMAT } from 'constants.js';

export const roundNumeric = val => (((val === null) || (val === undefined)) ? null : +val.toFixed(1));
export const backendFileToDataURI = f => `data:${f.content_type};base64,${f.content_base64}`;

export const getLocaleDateFormat = (forDatePicker = false) => {
    const format = moment.localeData().longDateFormat('L');

    // react-datepicker's <DatePicker /> -component handles DD and YYYY differently than moment.
    // See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md

    return forDatePicker
        ? format.replace('DD', 'dd').replace('YYYY', 'yyyy')
        : format;
};

export const fetchLocale = () => (
    localStorage.getItem('locale') || (navigator.languages && navigator.languages[0]) || navigator.language || 'fi-FI');

export const changeLanguage = locale => {
    try {
        const translationsObj = require(`../i18n/${locale}.po.json`);
        addLocale(locale, translationsObj);
        setLocale(locale);
    } catch(e) {
        console.warn('could not load language: ', locale);
        setLocale('fi');
    }
};

const setLocale = lang => {
    localStorage.setItem('locale', lang);
    moment.locale(lang);
    moment.weekdays(true);
    doUseLocale(lang);
};

export const formatter = new Intl.NumberFormat(fetchLocale(), {
    style: 'currency',
    currency: 'EUR',
});

export const formatDateTime = dateTime => {
    const currentLocale = fetchLocale();

    try {
        const newDate = new Date(dateTime);

        const date = new Intl.DateTimeFormat(
            currentLocale,
            { day: '2-digit', month: '2-digit', year: 'numeric' },
        ).format(newDate);

        const time = new Intl.DateTimeFormat(
            currentLocale,
            { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' },
        ).format(newDate);

        const full = date.concat(' ', time);

        return { full, date, time };
    } catch(e) { console.error(e); }
};

export const isValidBackendTime = str => moment(str, BACKEND_DATETIME_FORMAT).isValid();

export const getParsedLSItemOr = (key, fallback, isInt) => {
    const value = localStorage.getItem(key);

    if(!value) return fallback;

    let parsedValue = null;
    try {
        parsedValue = JSON.parse(value);
    } catch(e) {
        console.warn('Error parsing localStorage. Clearing localStorage.', e, localStorage, key, fallback, isInt);
        localStorage.clear();
    }

    if(isInt) {
        if(Number.isNaN(parsedValue) || parsedValue === undefined || parsedValue === null) return fallback;
        else return parseInt(parsedValue, 10);
    }

    return parsedValue;
};

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
});

export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for(let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for(let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const FUEL_TYPES = () => ({
    Gasoline: t`GASOLINE`,
    Petrol: t`PETROL`,
    Oil: t`OIL`,
    Kerosene: t`KEROSENE`,
    SuperPlus: t`SUPERPLUS`,
    Super: t`SUPER`,
    Diesel: t`DIESEL`,
    Biodiesel: t`BIODIESEL`,
    Gas: t`GAS`,
    LPG: t`LPG`,
    NG: t`NG`,
    CNG: t`CNG`,
    'H-gas': t`H-GAS`,
    'L-gas': t`L-GAS`,
    'HL-gas': t`HL-GAS`,
    Biomethane: t`BIOMETHANE`,
    LNG: t`LNG`,
    LNG20: t`LNG20`,
    Alcohol: t`ALCOHOL`,
    E85: t`E85`,
    Methanol: t`METHANOL`,
    Ethanol: t`ETHANOL`,
    ED95: t`ED95`,
    'Bio-Ethanol': t`BIO-ETHANOL`,
    Hydrogen: t`HYDROGEN`,
    Electricity: t`ELECTRICITY`,
    Hybrid: t`HYBRID`,
    'Petrol-LPG': t`PETROL-LPG`,
    'Petrol-NG': t`PETROL-NG`,
    'Petrol-CNG': t`PETROL-CNG`,
    'Petrol-Gas': t`PETROL-GAS`,
    'Super-NG': t`SUPER-NG`,
    'Petrol-E85': t`PETROL-E85`,
    'Petrol-Ethanol': t`PETROL-ETHANOL`,
    'Petrol-Methanol': t`PETROL-METHANOL`,
    'Petrol-Hydrogen': t`PETROL-HYDROGEN`,
    'Petrol-Electricity': t`PETROL-ELECTRICITY`,
    'SuperPlus-Electricity': t`SUPERPLUS-ELECTRICITY`,
    'Super-Electricity': t`SUPER-ELECTRICITY`,
    'Diesel-Electricity': t`DIESEL-ELECTRICITY`,
    'Biodiesel-Electricity': t`BIODIESEL-ELECTRICITY`,
    'Diesel-CNG': t`DIESEL-CNG`,
    'Diesel-LPG': t`DIESEL-LPG`,
    'Biodiesel-CNG': t`BIODIESEL-CNG`,
    'Biodiesel-LPG': t`BIODIESEL-LPG`,
    'Diesel-LNG': t`DIESEL-LNG`,
    'Diesel-LNG20': t`DIESEL-LNG20`,
    'Diesel-Ethanol': t`DIESEL-ETHANOL`,
    'Diesel-Methanol': t`DIESEL-METHANOL`,
    'Biodiesel-Ethanol': t`BIODIESEL-ETHANOL`,
    'Biodiesel-Methanol': t`BIODIESEL-METHANOL`,
    'NG-Hydrogen': t`NG-HYDROGEN`,
    'LPG-Electricity': t`LPG-ELECTRICITY`,
    'NG-Electricity': t`NG-ELECTRICITY`,
    'E85-Electricity': t`E85-ELECTRICITY`,
    'Hydrogen-Electricity': t`HYDROGEN-ELECTRICITY`,
});
